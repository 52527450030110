import { useState,useEffect } from "react";
import Navbar from "../components/Navbar";
import SymbolFacebookItemLink from "../components/SymbolFacebookItemLink";
import FrameComponent4 from "../components/FrameComponent4";
import "./Events.css";
import FrameComponent from "../components/FrameComponent";
import SubscribeInputButton from "../components/SubscribeInputButton";
import Section from "../components/Section";
import BlogItem from "../components/BlogItem";
import { useParams } from "react-router-dom";
import ListContainer from "../components/ListContainer";
import { fetchLegalUpdateById } from "../utils/api";
import LegalItem from "../components/LegalItem";
import LegalDetailRight from "../components/LegalDetailRight";
import Footer from "../components/Footer";

const LegalDetailPage = () => {
  const { id } = useParams();
  const [legal, setLegal] = useState(null); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const legal = await fetchLegalUpdateById(id);
        setLegal(legal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);
  

  return (
    <>
    <div className="blogs">
      <Navbar
        heading1Defective3MEarplu="Blogs"
        orderedListItemLitigation="Blogs"
        ColorLegal={'#e86500'}
      />
        <ListContainer 
        heading1AboutUS={legal?.title}
        orderedListItemAboutUs={legal?.title}
        propBackgroundImage="url('/legalupdates.jpg')"
      />
      <section className=" practice-area-capital-markets">
        <div className="left-column">
        {legal && <LegalItem legal={legal} />}
     
        </div>
        <div className="right-column">
        <LegalDetailRight />
        </div>
      </section>
    
    </div>
      {/* <footer className="section">
      <FrameComponent/>
      <SubscribeInputButton />
    </footer> */}
    <Footer/>
    <Section/>
    </>
  );
};

export default LegalDetailPage;

// import { useParams } from "react-router-dom";
// import { useState,useEffect } from "react";
// import Navbar from "../components/Navbar";
// import FrameComponent8 from "../components/FrameComponent8";
// import FrameComponent7 from "../components/FrameComponent7";
// import FrameComponent6 from "../components/FrameComponent6";
// import FrameComponent5 from "../components/FrameComponent5";
// import "./BlogsDetailPage.css";
// import FrameComponent from "../components/FrameComponent";
// import SubscribeInputButton from "../components/SubscribeInputButton";
// import Section from "../components/Section";


// const BlogsDetailPage = () => {
//   const [blog, setBlog] = useState(null);

//   let { id } = useParams();
//   const fetchBlog = async () => {
//     try {
//       const response = await fetch(`http://localhost:8000/api/blogs/${id}`);
//       const data = await response.json();
//       setBlog(data); // Set the fetched data to the state
//     } catch (error) {
//       console.error("Error fetching blog:", error);
//     }
//   };

//   useEffect(() => {
//     fetchBlog(); 
//   }, []); 

//   return (

//     <div className="blogs-detail-page">
//       <Navbar
//        heading1Defective3MEarplu="Blogs"
//        orderedListItemLitigation="Blogs"
//        ColorBlogs={'#eb934f'}
//       />
      
//       <FrameComponent8 />
//       <section className="blog-image3-750x465jpg-parent">
//         <img
//           className="blog-image3-750x465jpg-icon"
//           loading="eager"
//           alt=""
//           src="/blogimage3750x465jpg@2x.png"
//         />
//         <FrameComponent7 />
    
//       </section>
//       <footer className="section">
//          <FrameComponent/>
//          <SubscribeInputButton />
//        </footer>
//        <Section/>
//     </div>
//   );
// };

// export default BlogsDetailPage;
    {/* <div className="case-6jpg-wrapper">
          <img
            className="case-6jpg-icon"
            loading="eager"
            alt=""
            src="/case-6jpg@2x.png"
          />
        </div>
        <div className="heading-3-lopez-has-reported-parent">
          <b className="heading-36">
            Lopez has reportedly added to her real home in California
          </b>
          <div className="lo-managed-to-container">
            <span>
              <p className="lo-managed-to">
                Lo managed to make it hers for $28 million. As the Bronx native
                acquires a new home in California, she is
              </p>
              <p className="trying-to-sell">
                trying to sell a gated compound in the Golden State. The 17,000
                square-foot Hidden Hills property with
              </p>
              <p className="mountain-views-boasts">
                mountain views boasts nine bedrooms, including a master suite
                with private terrace and an entertainment
              </p>
              <p className="wing-which-includes">
                wing, which includes a 20-seat theater, dance studio and
                recording studio. China’s youngest female billionaire
              </p>
              <p className="has-unloaded-her">
                has unloaded her triplex penthouse in Sydney.
              </p>
            </span>
          </div>
          <div className="the-17000-square-foot-container">
            <span>
              <p className="the-17000-square-foot">
                The 17,000 square-foot Hidden Hills property with mountain views
                boasts nine bedrooms, includin. master
              </p>
              <p className="suite-with-private">
                suite with private terrace and an entertainment wing .
              </p>
            </span>
          </div>
          <div className="following-years-of-container">
            <span>
              <p className="following-years-of">
                Following years of white-hot growth, luxury home prices in
                Sydney declined for the first time in years, slipping
              </p>
              <p className="between-the-second">
                1% between the second quarter and third quarter of 2018,
                according to the latest report from brokerage
              </p>
              <p className="knight-frankthe-nearly">
                Knight Frank.The nearly 6,500-square-foot apartment has sweeping
                views.
              </p>
            </span>
          </div>
        </div>
        <div className="case-3jpg-wrapper">
          <img
            className="case-3jpg-icon"
            loading="eager"
            alt=""
            src="/case-3jpg@2x.png"
          />
        </div>
        <div className="heading-3-the-property-comp-parent">
          <b className="heading-3-container">
            <p className="the-property-complete">
              The property, complete with a 30-seat screening room, a 100-seat
              amp
            </p>
            <p className="hitheater-and-a">
              hitheater and a swimming pond with sandy beach
            </p>
          </b>
          <div className="she-is-trying-container">
            <span>
              <p className="she-is-trying">
                She is trying to sell a gated compound in the Golden State. The
                17,000-square-foot Hidden Hills property with
              </p>
              <p className="mountain-and-city">
                mountain and city views boasts nine bedrooms, including a master
                suite with private terrace and an
              </p>
              <p className="entertainment-wing-which">
                entertainment wing, which includes a 20-seat theater
              </p>
            </span>
          </div>
        </div> */}
        {/* <div className="banner-1024x267png-wrapper">
          <img
            className="banner-1024x267png-icon"
            loading="eager"
            alt=""
            src="/banner1024x267png@2x.png"
          />
        </div> */}
        {/* <FrameComponent6 />
        <FrameComponent5 /> */}
        {/* <div className="form-input1">
          <div className="post-comment">Post Comment</div>
        </div> */}