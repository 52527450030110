import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function TranslateIcon() {
    const translatePage = () => {
        const apiKey = '';
        const sourceLanguage = 'en'; 
        const targetLanguage = 'ar'; 
      
        const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
        const content = document.documentElement.innerHTML;
      
        axios.post(url, {
          q: content,
          source: sourceLanguage,
          target: targetLanguage,
        })
        .then(response => {
          const translatedContent = response.data.data.translations[0].translatedText;
          document.documentElement.innerHTML = translatedContent;
        })
        .catch(error => {
          console.error('Translation failed:', error);
        });
      };
  return (
    <FontAwesomeIcon icon={faGlobe} onClick={translatePage}/>
  );
}

export default TranslateIcon;