import { useEffect } from "react";
// import { Link } from "react-router-dom";
import './BlogItem.css'
const baseURL = process.env.REACT_APP_BASE_URL;
const PublicationItem = ({ publication }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="symbol-facebook-item-link" style={{ border: "1px solid #eee", borderRadius: "2px", padding: "40px" }}>
      <div className="blog-card">
        <img
          className="article-link-blog-image3-7"
          loading="eager"
          alt=""
          src={`${baseURL}/${publication.imgUrl}`}
        />
        <div className="symbol-twitter-item-link">
          <div className="article">
            <div className="list-item-attorneys">
              <div className="list-item-practice-areas d-flex pt-2">
                <div className="frame-careers p-1 pe-4">
                  <div className="link-privsa"><img className="pe-1" src="/blogauthor.svg" />{publication.author}</div>
                </div>
                <div className="link-privacy-policy p-1 pe-4">
                  <div className="september-15-20202"><img className="pe-1" src="/calender.svg" /> {formatDate(publication.date)}</div>
                </div>
                <div className="frame-head-office p-1 pe-4">
                  <div className="link-litigation2"><img className="pe-1" src="/category.svg" />{publication.category}</div>
                </div>
              </div>
              <h2 className="heading-2-container4 mt-4" style={{ marginBottom: "20px" }}>
                {publication.title}
              </h2>
            </div>
            <div className="main-complementary">
              <div className="struggling-to-sell-container" >
                <div dangerouslySetInnerHTML={{ __html: publication.content }}  />
              </div>
            </div>

            {/* <Link className="read-btn" style={{ background: "#eb934f", textDecoration: "none", color: "white", padding: "10px 20px", borderRadius: "5px", marginTop: "10px" }} to={`/blogpage/${blog.id}`} >Read More <img src="/Symbol6.svg" /></Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationItem;
