
import { useState } from "react";
import "./Section.css";


const Section = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <section className="section32">
      {/* <img
        className="link-footer-logopng4"
        loading="eager"
        alt=""
        src="/link--footer-logopng@2x.png"
      /> */}
      <div className="footer-links">
        {/* <div className="privacy-policy">
          <div className="legal-infos">
            <div className="symbol154"></div>
            <div className="list-item120">Privacy Policty</div>
          </div>
          <div className="legal-infos1">
            <div className="symbol155"></div>
            <div className="list-item121">Legal info</div>
          </div>
          <div className="legal-infos2">
            <div className="symbol156"></div>
            <div className="list-item122">Terms of Conditions</div>
          </div>
        </div> */}
        <div className="privsa-all-rights-container4">
        <span>{`©${currentYear}, `}</span> 
          <span className="privsa4">Al Jneibi & Partners </span>
          <span>. All Rights Reserved.</span>
        </div>
      </div>
    </section>
  );
};

export default Section;
