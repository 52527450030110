import { Visibility } from "@mui/icons-material";
import FormComponent from "./FormComponent";
import "./WelcomeHeader.css";


const WelcomeHeader = () => {
  return (
    <section className="welcome-header" data-aos='fade-up'>
      <div className="section26">
        <div className="graphic-container">
          <div className="display-frame">
            <div className="social-button">
              {/* <div className="heading-321">Get a Quote</div> */}
              <h1 className="heading-2-container3">
                <p className="get-in-touch">Get in Touch with</p>
                <p className="our-experts">Our Experts</p>
              </h1>
            </div>
            <div className="capital-markets-hub">
              <div className="divelementskit-infobox5">
                <div className="heading-322">Talk to an Expert</div>
                
                <div className="our-services1"><span><img className="pe-3" src='/Symbol4.svg'/></span>+971543000775</div>
              </div>
              <div className="service-symbol">
                <div className="divelementskit-infobox6">
                  <div className="heading-323" style={{visibility:'hidden'}}>We’re in Social</div>
                </div>
                {/* <div className="link-collection">
                  <img
                    className="item-link-twitter5"
                    alt=""
                    src="/item--link--twitter.svg"
                  />
                    <img
                    className="item-link-twitter5"
                    alt=""
                    src="/facebook.svg"
                  />
                    <img
                    className="item-link-twitter5"
                    alt=""
                    src="/linkedin.svg"
                  />
                  <div className="symbol129"></div> 
                   <img
                    className="item-link-whatsapp"
                    alt=""
                    src="/item--link--whatsapp.svg"
                  /> 
                  {/* <div className="symbol130"></div> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="privacy-policy-header">
          <div className="list-of-links">
            <div className="info-items-container">
              <div className="divelementor-widget-container5">
                <img className="svg-icon1" alt="" src="/svg-3.svg" />
              </div>
              <div className="input5">
                <input
                  type="text"
                  className="full-name1"
                  placeholder="FULL NAME"
                  style={{textTransform: 'none'}}
                />
              </div>

            </div>
            <div className="heading5">
              <div className="divelementor-widget-container6">
                <img className="svg-icon2" alt="" src="/svg-4.svg" />
              </div>
              <div className="input5">
                <input
                  type="email"
                  className="full-name1"
                  placeholder="EMAIL"
                  style={{textTransform: 'none'}}
                />
              </div>
            </div>
          </div>
          <div className="list-of-links1">
            <div className="divelementor-widget-container-parent">
              <div className="divelementor-widget-container7">
                <img className="svg-icon3" alt="" src="/svg-5.svg" />
              </div>
              <div className="input5">
                <input
                  type="tel"
                  className="full-name1"
                  placeholder="PHONE NUMBER"
                  style={{textTransform: 'none'}}
                />
              </div>
            </div>
            <div className="divelementor-widget-container-group">
              <div className="divelementor-widget-container8">
                <img className="svg-icon4" alt="" src="/svg-6.svg" />
              </div>
              <div className="input5">
                <input
                  type="tel"
                  className="full-name1"
                  placeholder="SUBJECT"
                  style={{textTransform: 'none'}}
                />
              </div>
            </div>
          </div>
          <div className="heading6">
            <div className="form-section-section2">
              <div className="divelementor-widget-container9">
                <img className="svg-icon5" alt="" src="/svg-7.svg" />
              </div>
              <textarea
                className="full-name1"
                placeholder="MESSAGE"
                rows="4"
                cols="50"
                style={{marginLeft: '0px',color:'black',outline: 'none'}}
              ></textarea>
            </div>
            <div className="button8">
              <div className="get-free-quote">{`Get Free Quote `}</div>
              <div className="symbol131"><img src="/Symbol-16.svg"/></div>
            </div>
          </div>
        </div> */}
        <FormComponent/>
      </div>
    </section>
  );
};

export default WelcomeHeader;
