import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import './App.css'
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import OurServices from "./pages/OurServices"; 
import OurServicesDetailPage from "./pages/OurServicesDetailPage";
import OurTeam from "./pages/OurTeam";
import Blogs from "./pages/Blogs";
import BlogsDetailPage from "./pages/BlogsDetailPage";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
// import News from './pages/News';
import Events from './pages/Events';
import Insights from './pages/Insights';
import LegalUpdates from './pages/LegalUpdates';
import Publications from './pages/Publications';
import Careers from './pages/Careers';
import EventsDetailPage from './pages/EventsDetailPage';
import PublicationDetailPage from './pages/PublicationDetailPage';
import LegalDetailPage from './pages/LegalDetailPage';
import WhatsAppButton from './components/WhatsappButton';
import Aos from 'aos';
import 'aos/dist/aos.css';


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/about-us":
        title = "";
        metaDescription = "";
        break;
      case "/our-services":
        title = "";
        metaDescription = "";
        break;
      case "/our-services-detail-page":
        title = "";
        metaDescription = "";
        break;
      case "/our-team":
        title = "";
        metaDescription = "";
        break;
      case "/blogs":
        title = "";
        metaDescription = "";
        break;
      case "/blogs-detail-page":
        title = "";
        metaDescription = "";
        break;
      case "/contact-us":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    let timeoutId;
  
    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (pathname !== '/about-us') {
          window.location.href = '/about-us';
        }
      }, 200000);
    };
  
    const handleMouseMove = () => {
      resetTimer();
    };
  
    document.addEventListener('mousemove', handleMouseMove);
    resetTimer();
  
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [pathname]);
  useEffect(() =>{
    Aos.init({ duration:1000})
    },[])
  

  const phoneNumber = '971549988140';
  return (
    <div className="App">
      
      <ToastContainer autoClose={3000} /> 
      <WhatsAppButton phoneNumber={phoneNumber} />
      <Routes>
      
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventsDetailPage />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/publications/:id" element={<PublicationDetailPage />} />
        <Route path="/legal-updates" element={<LegalUpdates />} />
        <Route path="/legal-updates/:id" element={<LegalDetailPage />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/service-detail" element={<OurServicesDetailPage />} />
      </Routes>
    </div>
  );
}

export default App;
