import DivelementorWidgetContainer from "./DivelementorWidgetContainer";
import "./Section2.css";

const Section2 = () => {

  return (
    <section className="section21" data-aos='fade-up'>
      <div className="section22">
        <div className="heading-3-why-us-parent">
          <div className="heading-313">Why us</div>
          <h1 className="heading-2-container2">
            <p className="why-should-you">Why Should you Hire</p>
            <p className="privsa-attorneys">Al jneibi Attorneys ?</p>
          </h1>
        </div>
        <div className="divelementor-widget-container4">
          <div className="divelementskit-card3">
            <div className="link-you-deserve-time-to-rec-parent">
        
              <b className="link-you">Client-Centric Approach</b>
              {/* <img className="link-icon6" alt="" src="/link-5.svg" /> */}
            </div>
            <div className="getting-into-an-container">
              <span>
                <p className="getting-into-an ">
                We prioritize client satisfaction and maintain open communication channels to address their concerns promptly and effectively.
                </p>
              </span>
            </div>
            <div className="link-you-deserve-time-to-rec-parent">
        
        <b className="link-you">Expertise and Experience</b>
        {/* <img className="link-icon6" alt="" src="/link-5.svg" /> */}
      </div>
      <div className="getting-into-an-container">
        <span>
          <p className="getting-into-an ">
          Our team comprises seasoned legal professionals with diverse expertise and extensive experience in handling complex legal matters.
          </p>
        </span>
      </div>
      <div className="link-you-deserve-time-to-rec-parent">
        
        <b className="link-you">Local Insight, Global Perspective</b>
        {/* <img className="link-icon6" alt="" src="/link-5.svg" /> */}
      </div>
      <div className="getting-into-an-container">
        <span>
          <p className="getting-into-an ">
          As a prominent legal firm in Dubai, UAE, we combine our deep understanding of local laws and regulations with a global perspective to offer holistic legal solutions.
          </p>
        </span>
      </div>
          </div>
          {/* <div className="divelementskit-card4">
            <b className="link-dont">Don't settle for less than you deserve</b>
            <img className="link-icon7" alt="" src="/link-6.svg" />
          </div>
          <div className="divelementskit-card5">
            <b className="link-damages">
              Damages are available after a NYC accident
            </b>
            <img className="link-icon8" alt="" src="/link-6.svg" />
          </div>
          <div className="divelementskit-card6">
            <b className="link-experience">Experience you Can Trust</b>
            <img className="link-icon9" alt="" src="/link-6.svg" />
          </div> */}
        </div>
      </div>
      <div className="heading3">
        <img
          className="section-icon"
          loading="eager"
          alt=""
          src="/case-3jpg@2x.png"
        />
        <div className="divelementor-container">
          <DivelementorWidgetContainer
            featureIcon3png="/feature-icon-3png@2x.svg"
            heading3409=""
            qualifiedLawyers="Qualified Lawyers"
          />
          <DivelementorWidgetContainer
            featureIcon3png="/feature-icon-4png@2x.svg"
            heading3409=""
            qualifiedLawyers="Ranked in Super Law"
            propPadding="0px var(--padding-6xs) 0px var(--padding-smi)"
            propBorderRight="1px solid var(--color-slategray-400)"
            propBorderRadius="unset"
            propWidth="52px"
          />
          <DivelementorWidgetContainer
            featureIcon3png="/feature-icon-2png@2x.svg"
            heading3409=""
            qualifiedLawyers="Top Legal Expertise"
            propPadding="0px var(--padding-mini) 0px var(--padding-lgi)"
            propBorderRight="unset"
            propBorderRadius="5px"
            propWidth="46px"
          />
        </div>
      </div>
    </section>
  );
};

export default Section2;
