import "./DivelementorWidgetWrap1.css";

const DivelementorWidgetWrap1 = ({
  team6png,
  heading3LinkKimballJones,
  litigationAssociate,
}) => {
  return (
    <div className="divelementor-widget-wrap7">
      <img className="team-6png-icon1" loading="eager" alt="" src={team6png} />
      <div className="frame-d-stewart">
        <b className="heading-335">{heading3LinkKimballJones}</b>
        <div className="litigation-associate2">{litigationAssociate}</div>
      </div>
    </div>
  );
};

export default DivelementorWidgetWrap1;
