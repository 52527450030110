import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import ListContainer from '../components/ListContainer';
import SymbolFacebookItemLink from '../components/SymbolFacebookItemLink';
import FrameComponent4 from '../components/FrameComponent4';
import NewsRight from '../components/EventsRight';
import NewsLeft from '../components/EventsLeft';
import './Events.css'
import LegalLeft from '../components/LegalLeft';
import LegalRight from '../components/LegalRight';
import Section from '../components/Section';
function LegalUpdates() {
  return (
    <>
    <Navbar ColorLegal={'#e86500'}/>
    <ListContainer 
        heading1AboutUS="Legal Updates"
        orderedListItemAboutUs="Legal Updates"
        propBackgroundImage="url('/legalupdates.jpg')"
      />
       <section className=" practice-area-capital-markets">
        <div className="left-column">
        <LegalLeft/>
        </div>
        <div className="right-column" >
        <LegalRight />
        </div>
      </section>
    <Footer/>
    <Section/>
    </>
  )
}

export default LegalUpdates;