import { useState,useEffect } from "react";
import "./FrameComponent4.css";
import { Link } from "react-router-dom";
import { fetchPublications } from "../utils/api";



const PublicationDetailRight = () => {
  const [publications, setPublications] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const publications = await fetchPublications();
        setPublications(publications); // Set the state with the fetched data
      } catch (error) {
        console.error('Error fetching publications:', error);
      }
    };
  
    fetchData();
  }, []);
  return (
    <>
    
     <div  className="input-search-main-frame-parent">
   
      <div className="main-complementary1">
        <div className="heading-recent-posts">
          <div className="heading-5-recent-posts-parent">
            <h3 className="heading-5">Recent Posts</h3>
            <div className="heading-51" />
          </div>
        </div>
        <div className="capital-markets-link">
        {publications.map(publication => (
          <div key={publication.id} className="list-item161">
            <div className="link-defective-container">
              <span>
           <Link className="blog-link" to={`/legal-updates/${publication.id}`} ><p className="defective-3m-earplugs2">{publication.title}</p></Link>
                <p className="associated-with-tinnitus1">
                </p>
              </span>
            </div>
          </div>))}
          
          {/* <div className="list-item162">
            <div className="link-depositions-container">
              <span>
                <p className="depositions-reveal-no1">
                  Depositions Reveal No Proof in
                </p>
                <p className="warned-military-of">Warned Military of Earplug</p>
              </span>
            </div>
          </div>
          <div className="list-item163">
            <div className="link-proin-container">
              <span>
                <p className="proin-gravida-nibh1">
                  Proin gravida nibh vel velit
                </p>
                <p className="aenean-sollicitudin1">Aenean sollicitudin…</p>
              </span>
            </div>
          </div> */}
     
        </div>
      </div>
      
    
    </div>
    <div className="section3">
              <div className="divelementor-background-overl1" />
              <div className="heading-2-need-help-with-any-wrapper">
                <b className="heading-2-container1">
                  <span>
                    <p className="need-help-with">Need help with any</p>
                    <p className="legal-issues">legal issues?</p>
                  </span>
                </b>
              </div>
              <div className="form-section-section">
                <div className="symbol8"></div>
              </div>
              <div className="form-section-section-inp-parent">
                <div className="form-section-section-inp">
                  {/* <div className="form-section-section1">
                    <div className="symbol9"></div>
                  </div> */}
                  {/* <div className="full-name-wrapper">
                    <div className="full-name">{`Full name `}</div>
                  </div> */}
                </div>
                {/* <div className="form-section-section-inp1">
                  <div className="email">{`Email `}</div>
                </div> */}
              </div>
              <div className="input-form-section">
              <a className="contact-btn" style={{zIndex:'2', color:'white', textDecoration:'none'}} href="/contact-us"> <div className="form-section-section-but">
                  <div className="contact-us">Contact Us</div>
                </div></a>
              </div>
            </div>
    </>
  );
};

export default PublicationDetailRight;
