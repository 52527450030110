import { useCallback } from "react";
import Navbar from "../components/Navbar";
import Section4 from "../components/Section4";
import Bankruptcy from "../components/Bankruptcy";
import SocialMediaLinksList from "../components/SocialMediaLinksList";
import InfoprivsacomLink from "../components/InfoprivsacomLink";
import Section2 from "../components/Section2";
import FrameComponent1 from "../components/FrameComponent1";
import LegalHeadersWithListItems from "../components/LegalHeadersWithListItems";
import Section1 from "../components/Section1";
import WelcomeHeader from "../components/WelcomeHeader";
import Link from "../components/Link";
import FrameComponent from "../components/FrameComponent";
import SubscribeInputButton from "../components/SubscribeInputButton";
import Section from "../components/Section";
import "./HomePage.css";

const HomePage = () => {

  return (
    <div className="home-page-1 page-turn">
      <Navbar  ColorHome={'#e86500'}/>
      <Section4 />
      <Bankruptcy />
      <SocialMediaLinksList />
      {/* <InfoprivsacomLink /> */}
      <Section2 />
      {/* <FrameComponent1 /> */}
      {/* <LegalHeadersWithListItems /> */}
      <Section1 />
      <WelcomeHeader />
      <Link />
      <FrameComponent />
      <Section />
    </div>
  );
};

export default HomePage;
