import { useMemo } from "react";
import "./MainSectionSection1.css";

const MainSectionSection1 = ({
  symbol,
  heading3LinkCapitalMarket,
  theDemandAndAppetiteImpro,
  technologyIsOnly,
  propBackgroundImage,
  propWidth,
  propPadding,
  propWidth1,
  propMinWidth,
  propLeft,
}) => {
  const mainSectionSectionStyle = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
    };
  }, [propBackgroundImage]);

  const frameDiv1Style = useMemo(() => {
    return {
      width: propWidth,
      padding: propPadding,
    };
  }, [propWidth, propPadding]);

  const symbolStyle = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  const frameDiv2Style = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const linkIconStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  return (
    <div className="main-section-section" style={mainSectionSectionStyle}>
      <div className="divservice-content4">
        <div className="before8" />
        <div className="divservice-content-inner1" style={frameDiv1Style}>
          <div className="symbol-parent24">
            {/* <h1 className="symbol192" style={symbolStyle}>
              {symbol}
            </h1> */}
            <div className="frame-parent9" style={frameDiv2Style}>
              <div className="heading-3-link-capital-mar-parent">
                <h2 className="heading-329 ;">{heading3LinkCapitalMarket}</h2>
                <div className="the-demand-and-container8 ">
                  <span>
                    <p className="the-demand-and8">
                      {theDemandAndAppetiteImpro}
                    </p>
                    {/* <p className="technology-is-only1">{technologyIsOnly}</p> */}
                  </span>
                </div>
              </div>
              {/* <img
                className="link-icon10"
                loading="eager"
                alt=""
                src="/link.svg"
                style={linkIconStyle}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSectionSection1;
