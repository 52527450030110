import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListFrame from './ListFrame';
import './Link.css';
import { fetchLegalUpdates } from '../utils/api';

const Link = () => {
  const [legalupdates, setLegalupdates] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const legalupdates = await fetchLegalUpdates();
        setLegalupdates(legalupdates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="link8" data-aos='fade-up'>
      <div className="text1">
        <div className="link-parent">
          <div className="link9">
            <div className="text2">
              <div className="section-heading6">Legal Updates</div>
              <h1 className="section-heading7">From the Legal Updates</h1>
            </div>
          </div>
          <div className="heading7">
            <div className="link-group">
              {legalupdates.slice(0, 3).map((legalupdate, index) => (
                <div key={index} className="link-group">
                  <ListFrame
                    date={formatDate(legalupdate.date)}
                    category={legalupdate.category}
                    defective3MEarplugs={legalupdate.title}
                    author={legalupdate.author}
                    summary={legalupdate.summary}
                    link={`/legal-updates/${legalupdate.id}`}
                    
                    // associatedWithTinnitus={legalupdate.title}
                    // link={`/legalupdatepage/${legalupdate.id}`}
                    sectionLink="/section--link.svg"
                    propMinWidth="224px" 
                    propFlex="1"
                    propPadding="var(--padding-11xs) var(--padding-lgi) 0px 0px"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="section-link-wrapper">
          <a className="legalupdates-link" href="/legal-updates">
            <div className="section-link5">
              <div className="section-container">
                <div className="section31">
                  <div className="view-all-news">View All Legal Updates</div>
                  <div className="symbol136"><img src="/Symbol6.svg" alt="symbol" /></div>
                </div>
              </div>
              <div className="after2" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Link;



// import ListFrame from "./ListFrame";
// import "./Link.css";

// const Link = () => {
  
//   return (
//     <section className="link8">
//       <div className="text1">
//         <div className="link-parent">
//           <div className="link9">
//             <div className="text2">
//               <div className="section-heading6">News Insights</div>
//               <h1 className="section-heading7">From the legalupdate</h1>
//             </div>
//           </div>
//           <div className="heading7">
//             <ListFrame
//               defective3MEarplugs="Defective 3M Earplugs"
//               associatedWithTinnitus="Associated With Tinnitus"
//               sectionLink="/section--link.svg"
//             />
//             <div className="link-group">
//               <ListFrame
//                 defective3MEarplugs="Depositions Reveal No Proof"
//                 associatedWithTinnitus="in Warned Military of Earplug"
//                 sectionLink="/section--link-1.svg"
//                 propMinWidth="224px"
//                 propFlex="1"
//                 propPadding="var(--padding-11xs) var(--padding-lgi) 0px 0px"
//               />
//               <div className="symbol133">
//                 <div className="symbol134">
//                   <div className="section29">
//                     <div className="september-15-20201">September 15, 2020</div>
//                   </div>
//                   <div className="section30">
//                     <div className="link-litigation-container">
//                       <div className="link-litigation1">Litigation</div>
//                     </div>
//                   </div>
//                 </div>
//                 <h3 className="section-heading-container1">
//                   <p className="proin-gravida-nibh">
//                     Proin gravida nibh vel velit
//                   </p>
//                   <p className="aenean-sollicitudin">Aenean sollicitudin…</p>
//                 </h3>
//                 <div className="symbol135">
//                   <div className="section-link-read-more-wrapper">
//                     <div className="section-link3">{`Read `}</div>
//                   </div>
//                   <img
//                     className="section-link4"
//                     alt=""
//                     src="/section--link-2.svg"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="section-link-wrapper">
//           <a className="legalupdates-link"href="/legalupdates">
//           <div className="section-link5">
//             <div className="section-container">
//               <div className="section31">
//                 <div className="view-all-news">{`View All News `}</div>
//                 <div className="symbol136"><img src="/Symbol6.svg"/></div>
//               </div>
//             </div>
//             <div className="after2" />
//           </div>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Link;
