import { useMemo } from "react";
import "./MainSectionSection.css";

const MainSectionSection = ({
  heading3LinkBankingFinanc,
  premierTrialLawyersFromEa,
  stateInTheNationWho,
  propBackgroundImage,
  propHeight,
  propFlex,
  propMinWidth,
  propAlignSelf,
  propHeight1,
}) => {
  const mainSectionSection1Style = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
      height: propHeight,
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propBackgroundImage, propHeight, propFlex, propMinWidth]);

  const divserviceContentStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      height: propHeight1,
    };
  }, [propAlignSelf, propHeight1]);

  return (
    <div className="main-section-section1" style={mainSectionSection1Style} data-aos='fade-up'>
      <div className="divservice-content5" style={divserviceContentStyle}>
        <div className="before9" />
        <div className="divservice-content-inner2">
          <div className="frame-parent10">
            <div className="heading-3-link-banking-f-parent">
              <b className="heading-330">{heading3LinkBankingFinanc}</b>
              <div className="premier-trial-lawyers-container1">
                <span>
                  <p className="premier-trial-lawyers1">
                    {premierTrialLawyersFromEa}
                  </p>
                  <p className="state-in-the">{stateInTheNationWho}</p>
                </span>
              </div>
            </div>
            {/* <img
              className="link-icon11"
              loading="eager"
              alt=""
              src="/link.svg"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSectionSection;
