import { useMemo, useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import TranslateIcon from './TranslateIcon';

const Navbar = ({
  listItemLink,
  groupDivGap,
  centralSceneRight,
  centralSceneLeft,
  sectionSectionPadding,
  starPerformerPadding,
  listItemLinkPadding,
  ColorHome,
  ColorAbout,
  ColorContact,
  ColorService,
  ColorTeam,
  ColorBlogs,
  ColorNews,
  ColorEvents,
  ColorLegal,
  ColorPublications,
  ColorCareers
}) => {


  const boxOfGoodiesStyle = useMemo(() => {
    return {
      gap: groupDivGap,
    };
  }, [groupDivGap]);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const insightsRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isDropdownOpen && insightsRef.current && dropdownRef.current) {
      const insightsRect = insightsRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${insightsRect.bottom}px`;
      dropdownRef.current.style.left = `${insightsRect.left}px`;
    }
  }, [isDropdownOpen]);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const logoNew11Style = useMemo(() => {
    return {
      right: centralSceneRight,
      left: centralSceneLeft,
    };
  }, [centralSceneRight, centralSceneLeft]);

  const centralSceneStyle = useMemo(() => {
    return {
      padding: sectionSectionPadding,
    };
  }, [sectionSectionPadding]);

  const sectionSectionStyle = useMemo(() => {
    return {
      padding: starPerformerPadding,
    };
  }, [starPerformerPadding]);

  const sectionSection1Style = useMemo(() => {
    return {
      padding: listItemLinkPadding,
    };
  }, [listItemLinkPadding]);

  return (
    
    <>
      <div className="custom-container">
        <img
          className="logo-new-1"
          loading="eager"
          alt=""

          src="/logo-2.png"

        />
      </div>
      <div className="box-of-goodies" style={boxOfGoodiesStyle}>
        <div className="rectangle-group">
          <div className="frame-inner" />
          <img
            className="logo-new-1-11"
            loading="eager"
            alt=""
            src="/logo-2.png"
            style={logoNew11Style}
          />
        </div>

        <div className="central-scene" style={centralSceneStyle}>
          {/* <div className="section-section2" style={sectionSectionStyle}>
            <div className="star-performer">
              <div className="symbol157">
                <img src="/Symbol-1.svg" />
              </div>
              <div className="list-item123">
                Office No.204, Wasl Business Central, Deira, Dubai
              </div>
            </div>

            <div className="vivid-visuals">
              <div className="list-item124" />
            </div>
            <div className="position-fix">
              <div className="symbol158">
                <img src="/time.svg" />
              </div>
              <div className="link-ladder">
                <div className="list-item125">Mon-Fri 9 AM - 5 PM</div>
              </div>
            </div>
            <div className="position-fix-2">
              <div className="symbol159">
                <img className="phone" src="/phonesymbol.svg" alt="phone"/>
              </div>
              <div className="list-item126">+971-42-551525</div>
            </div>
          </div> */}
          <header className="section-section3" style={sectionSection1Style}>
            <div className="list-item-link6" />
            <div>
              <FontAwesomeIcon
                icon={faBars}
                className={`menu-icon`}
                onClick={toggleMobileMenu}
              />
              <div className={`help-handler ${!showMobileMenu ? '' : 'display-block'}`}>
                <Link to="/"><div className="list-item127" style={{ color: ColorHome }}>HOME</div></Link>
                <Link to="/about-us"><div className="list-item128" style={{ color: ColorAbout }}>ABOUT US</div></Link>
                <div className="customer-connect">
                  <Link to="/our-services"><div className="list-item129" style={{ color: ColorService }}>OUR SERVICES</div></Link>
                  {/* <img className="list-item-link7" alt="" src="/Vector.svg" /> */}
                </div>
                {/* <a href="/blogs"><div className="list-item131" style={{ color: ColorBlogs }}>Blogs</div></a> */}
                {/* <a href="/news"><div className="list-item131" style={{ color: ColorNews }}>NEWS</div></a> */}
                <Link to="/events"><div className="list-item131" style={{ color: ColorEvents }}>EVENTS</div></Link>
                <Link to="/legal-updates"><div className="list-item131" style={{ color: ColorLegal }}>LEGAL UPDATES</div></Link>
                <Link to="/publications"><div className="list-item131" style={{ color: ColorPublications }}>PUBLICATIONS</div></Link>
                <Link to="/careers"><div className="list-item131" style={{ color: ColorCareers }}>CAREERS</div></Link>

                <Link to="/contact-us"> <button className="contact-button2">
                  Contact Us
            </button></Link>

                {/* <div  ref={insightsRef} className="list-item131" style={{ color: ColorInsights }} 
        onClick={toggleDropdown}>Insights <span><img className="list-item-link7" alt="" src="/Vector.svg" /> </span></div>
            {isDropdownOpen && (
        <div ref={dropdownRef} className="dropdown-content">
          <a className="list1" href="/legal-updates" >Legal Updates</a><br/>
          <a className="list2" href="/publications">Publications</a>
        </div>
      )} */}
                {/* <Link to="/contact-us"><div className="list-item132" style={{ color: ColorContact }}>Contact Us</div></a> */}
                {/* <img
                className="link-navsearch-button1"
                loading="eager"
                alt=""
                src="/link--navsearchbutton.svg"
              /> */}
               
              </div>

             
            </div>
            <div>
            <Link to="/contact-us"> <button className="contact-button1">
                  Contact Us
            </button></Link>

            </div>
           
          </header>
        </div>
      </div>
    </>
  );
};

export default Navbar;
