import { useState, useEffect } from "react";
import "./FrameComponent4.css";
import { Link } from "react-router-dom";
import { fetchEvents } from "../utils/api";

const EventsRight = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const events = await fetchEvents();
        setEvents(events);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="center-the-component">
      <div className="input-search-main-frame-parent">
        <div className="main-complementary1">
          <div className="heading-recent-posts">
            <div className="heading-5-recent-posts-parent">
              <h3 className="heading-5">Recent Events</h3>
              <div className="heading-51" />
            </div>
          </div>
          <div className="capital-markets-link">
            {events.map(event => (
              <div key={event.id} className="list-item161">
                <div className="link-defective-container">
                  <span>
                    <Link className="blog-link" to={`/events/${event.id}`} >
                      <p className="defective-3m-earplugs2">{event.title}</p>
                      </Link>
                    <p className="associated-with-tinnitus1">
                    </p>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section3">
        <div className="divelementor-background-overl1" />
        <div className="heading-2-need-help-with-any-wrapper">
          <b className="heading-2-container1">
            <span>
              <p className="need-help-with">Need help with any</p>
              <p className="legal-issues">legal issues?</p>
            </span>
          </b>
        </div>
        <div className="form-section-section">
          <div className="symbol8"></div>
        </div>
        <div className="form-section-section-inp-parent">
          <div className="form-section-section-inp">
          </div>
        </div>
        <div className="input-form-section">
          <a className="contact-btn" style={{ zIndex: '2', color: 'white', textDecoration: 'none' }} href="/contact-us">
            <div className="form-section-section-but">
              <div className="contact-us">Contact Us</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventsRight;
