import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ListContainer from '../components/ListContainer'

function Insights() {
  return (
    <>
    <Navbar ColorInsights={'#e86500'}/>
    <ListContainer 
        heading1AboutUS="Insights"
        orderedListItemAboutUs="Insights"
      />
    <Footer/>
    </>
  )
}

export default Insights