import React from 'react';
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({ phoneNumber }) => {
  const whatsappLink = `https://wa.me/${phoneNumber}`;

  return (
    <Fab
      color="primary"
      aria-label="whatsapp"
      style={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        fill:'white !important'
      }}
      href={whatsappLink}
    >
      <WhatsAppIcon />
    </Fab>
  );
};

export default WhatsAppButton;
