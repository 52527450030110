import React from 'react'
import SubscribeInputButton from './SubscribeInputButton';
import FrameComponent from './FrameComponent';

function Footer() {
  return (
    <FrameComponent />
  )
}

export default Footer;