import { useMemo } from "react";
import "./ListFrame.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ListFrame = ({
  defective3MEarplugs,
  associatedWithTinnitus,
  sectionLink,
  propMinWidth,
  propFlex,
  propPadding,
  date,
  category,link,author,summary
}) => {
  const listFrameStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      flex: propFlex,
    };
  }, [propMinWidth, propFlex]);

  const section1Style = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const legal = await fetchLegalUpdateById(id);
  //       setEvent(legal);

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, [id]);
  
  return (
    <div className="list-frame" style={listFrameStyle}>
      <div className="symbol132">
        <div className="section27" style={section1Style}>
          <div className="september-15-2020">{date}</div>
        </div>
        <div className="section28">
          <div className="link-litigation-wrapper">
            <div className="link-litigation">{category}</div>
          </div>
        </div> <div className="section28">
          <div className="link-litigation-wrapper">
            <div className="link-litigation">{author}</div>
          </div>
        </div>
      </div>
      <h3 className="section-heading-container">
        <p className="defective-3m-earplugs">{defective3MEarplugs}</p>
        <p className="associated-with-tinnitus">{associatedWithTinnitus}</p>
      </h3>
      <p>{summary}</p>
      <Link className="link" to={link}>
        <div className="section-link-read-more-parent">
        <div className="section-link1">{`Read `}</div>
        <img className="section-link2" alt="" src={sectionLink} />
      </div></Link>
    </div>
  );
};

export default ListFrame;
