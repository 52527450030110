import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import ListContainer from '../components/ListContainer';
import SymbolFacebookItemLink from '../components/SymbolFacebookItemLink';
import FrameComponent4 from '../components/FrameComponent4';
import NewsRight from '../components/EventsRight';
import NewsLeft from '../components/EventsLeft';
import './Events.css'
import EventsLeft from '../components/EventsLeft';
import EventsRight from '../components/EventsRight';
import Section from '../components/Section';
function Events() {
  return (
    <>
    <Navbar ColorEvents={'#e86500'}/>
    <ListContainer 
        heading1AboutUS="Events" 
        orderedListItemAboutUs="Events"
        propBackgroundImage="url('/events.jpg')"
      />
       <section className=" practice-area-capital-markets">
        <div className="left-column">
        <EventsLeft/>
        </div>
        <div className="right-column">
        <EventsRight />
        </div>
      </section>
    <Footer/>
    <Section/>
    </>
  )
}

export default Events;