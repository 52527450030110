import React, { useState } from 'react';
import axios from 'axios'; 
import './FrameComponent9.css';
import {toast} from "react-toastify";
import { submitForm2 } from '../utils/api';


const FrameComponent9 = () => {
 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  // const [error, setError] = useState('');
  // const [success, setSuccess] = useState('');

  const isValidPhoneNumber = (phoneNumber) => {
   
    const phoneRegex = /^\d{10}$/;
    
    return phoneRegex.test(phoneNumber);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.phone || !formData.subject || !formData.message) {
      toast.error('All fields are required');
      // setSuccess('');
      return;
    }
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error('Please enter a valid phone number');
      return;
    }
    try {
      await submitForm2(formData);
      toast.success('Form submitted successfully');
      // setError('');
     
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again later.');
      // setSuccess('');
    }
  };

  return (
    
    <section className="select1">
      <div className='contact-us'>
  
      </div>
      <div className="divmfinput">
        <div className="divelementorwidgetcontainer">
          <div className="fullname">
            <div className="divelementskit-infobox11">
              <div className="symbol213">
                {" "}
                <img className="phone" src="/Symbol10.svg" />
              </div>
              <div className="map">
                <h3 className="heading-338">Location:</h3>
                <div className="level-7-151-container">
                  <span>
                    <p className="level-7-151">
                      Office No.204, Wasal Business Central, Port Saeed, Deira,
                      Dubai-UAE
                    </p>
                    <p className="new-south-wales"></p>
                  </span>
                </div>
              </div>
            </div>
            <div className="divelementskit-infobox12">
              <div className="symbol214">
                {" "}
                <img className="phone" src="/Symbol001.svg" />
              </div>
              <div className="heading-3-phone-parent">
                <h3 className="heading-339">Phone:</h3>
                <div className="div">+971543000775, +97142551525</div>
              </div>
            </div>
            <div className="divelementskit-infobox13">
              <div className="symbol215">
                {" "}
                <img className="phone" src="/Symbol05.svg" />
              </div>
              <div className="heading-3-email-parent">
                <h3 className="heading-340">Email:</h3>
                <div className="examplewebsitecom">info@ajplawyers.ae</div>
              </div>
            </div>
          </div>
          <div className="iframe">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4722918453126!2d55.3357554!3d25.254693099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdff87e7343%3A0x34ac38c4a925d3d5!2sWasl%20Business%20Center!5e0!3m2!1sen!2sin!4v1708002234284!5m2!1sen!2sin"
              width="500"
              height="400"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="divelementor-widget-wrap9">
          
          <div className="heading12">
            <h1 className="heading-2-container6">
              <span>
                <p className="get-in-touch1">Get in touch with</p>
                <p className="our-experts1">our experts</p>
              </span>
            </h1>
          </div>
          <div className="heading13">
            <div className="input10">
              <div className="divelementor-widget-container10">
                <div className="symbol216">
                  <img src="/Symbol11.svg" />
                </div>
              </div>
              <div className="textarea-container">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Full name"
                  className="full-name2"
                  style={{textTransform:'none'}}
                />
              </div>
            </div>
            <div className="input11">
              <div className="divelementor-widget-container11">
                <div className="symbol217">
                  <img src="/Symbol12.svg" />
                </div>
              </div>
              <div className="email-wrapper">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="email9"
                  style={{textTransform:'none'}}
                />
              </div>
            </div>
            <div className="input12">
              <div className="divelementor-widget-container12">
                <div className="symbol218">
                  <img src="/Symbol13.svg" />
                </div>
              </div>
              <div className="phone-number-wrapper">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  className="phone-number1"
                  style={{textTransform:'none'}}
                />
              </div>
            </div>
            <div className="divmf-input1">
              <div className="divmf-select-control">
                <div className="divelementor-widget-container13">
                  <div className="symbol219">
                    <img  src="/Symbol14.svg" />
                  </div>
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                    className="select2"
                    style={{textTransform:'none'}}
                  />
                </div>
                <div className="divmf-select-indicators">
                  <div className="divmf-select-indicator1" />
                </div>
              </div>
            </div>
            <div className="textarea1">
              <div className="divelementor-widget-container14">
                <div className="symbol220">
                  <img src="/Symbol-15.svg" />
                </div>
              </div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                className="textarea2"
                rows="4"
                cols="50"
                style={{textTransform:'none',paddingLeft:'10px'}}
              ></textarea>
            </div>
          </div>
          {/* <div className="section-button">
            <div className="symbol221">
              <img src="/Symbol-16.svg" />
            </div> */}

            <button type="submit" className="button8 mt-2" style={{color:'white',fontWeight:"600",fontSize:'20px'}}>Get In Touch<img className='submit-icon' src="/Symbol-16.svg" /> </button>


          {/* </div> */}
          <div className='message'>
          {/* {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>} */}
          </div>
      
        </div>
        </form>
      
          
      </div>
    </section>
   
  );
};

export default FrameComponent9;

// above code is correct
// import "./FrameComponent9.css";

// const FrameComponent9 = () => {
//   return (
//     <section className="select1">
//       <div className="divmfinput">
//         <div className="divelementorwidgetcontainer">
//           <div className="fullname">
//             <div className="divelementskit-infobox11">
//               <div className="symbol213"> <img className="phone" src="/Symbol10.svg"/></div>
//               <div className="map">
//                 <h3 className="heading-338">Location:</h3>
//                 <div className="level-7-151-container">
//                   <span>
//                     <p className="level-7-151">
//                     Office No.204, Wasal Business Central, Port Saeed, Deira, Dubai-UAE
//                     </p>
//                     <p className="new-south-wales"></p>
//                   </span>
//                 </div>
//               </div>
//             </div>
//             <div className="divelementskit-infobox12">
//               <div className="symbol214">  <img className="phone" src="/Symbol001.svg"/></div>
//               <div className="heading-3-phone-parent">
//                 <h3 className="heading-339">Phone:</h3>
//                 <div className="div">+97137376611</div>
//               </div>
//             </div>
//             <div className="divelementskit-infobox13">
//               <div className="symbol215"> <img className="phone" src="/Symbol05.svg"/></div>
//               <div className="heading-3-email-parent">
//                 <h3 className="heading-340">Email:</h3>
//                 <div className="examplewebsitecom">info@aljneibi.com</div>
//               </div>
//             </div>
//           </div>
//           <div className="iframe">
//             <iframe
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4722918453126!2d55.3357554!3d25.254693099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdff87e7343%3A0x34ac38c4a925d3d5!2sWasl%20Business%20Center!5e0!3m2!1sen!2sin!4v1708002234284!5m2!1sen!2sin"
//               width="500"
//               height="400"
//               style={{border:"0"}}
//               allowfullscreen=""
//               loading="lazy"
//               referrerpolicy="no-referrer-when-downgrade"
//             ></iframe>
//           </div>
//         </div>
//         <div className="divelementor-widget-wrap9">
//           <div className="heading12">
//             <h1 className="heading-2-container6">
//               <span>
//                 <p className="get-in-touch1">Get in touch with</p>
//                 <p className="our-experts1">our experts</p>
//               </span>
//             </h1>
//           </div>
//           <div className="heading13">
//             <div className="input10">
//               <div className="divelementor-widget-container10">
//                 <div className="symbol216"><img src="/Symbol11.svg"/></div>
//               </div>
//               <div className="textarea-container">
//                 <div className="full-name2">{`Full name `}</div>
//               </div>
//             </div>
//             <div className="input11">
//               <div className="divelementor-widget-container11">
//                 <div className="symbol217"><img src="/Symbol12.svg"/></div>
//               </div>
//               <div className="email-wrapper">
//                 <div className="email9">{`Email `}</div>
//               </div>
//             </div>
//             <div className="input12">
//               <div className="divelementor-widget-container12">
//                 <div className="symbol218"><img src="/Symbol13.svg"/></div>
//               </div>
//               <div className="phone-number-wrapper">
//                 <div className="phone-number1">{`Phone Number `}</div>
//               </div>
//             </div>
//             <div className="divmf-input1">
//               <div className="divmf-select-control">
//                 <div className="divelementor-widget-container13">
//                   <div className="symbol219"><img src="/Symbol14.svg"/></div>
//                 </div>
//                 <div className="input-container">
//                   <div className="select2">Subject</div>
//                 </div>
//                 <div className="divmf-select-indicators">
//                   <div className="divmf-select-indicator1" />
//                 </div>
//               </div>
//             </div>
//             <div className="textarea1">
//               <div className="divelementor-widget-container14">
//                 <div className="symbol220"><img src="/Symbol-15.svg"/></div>
//               </div>
//             </div>
//           </div>
//           <div className="section-button">
//             <div className="symbol221"><img src="/Symbol-16.svg"/></div>
//             <div className="get-free-quote1">Get Free Quote</div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FrameComponent9;
