import { useMemo } from "react";
import "./ColumnLayout.css";

const ColumnLayout = ({
  symbol,
  heading3NoHiddenFees,
  nobodyLikesSurprisesOnThe,
  billsSoWeTakeTheTime,
  divelementskitBoxHeader,
  heading3PracticalAdvice,
  weTakeTheTimeToAgreeWith,
  youExactlyWhatOur,
  propFlex,
  propPadding,
  propPadding1,
  propWidth,
  propMinWidth,
  propHeight,
  propAlignSelf,
  propGap,
  propAlignSelf1,
  propFlex1,
  propMinWidth1,
  propFlex2,
  propHeight1,
}) => {
  const columnLayoutStyle = useMemo(() => {
    return {
      flex: propFlex,
      padding: propPadding,
    };
  }, [propFlex, propPadding]);

  const divelementskitInfoBoxIconStyle = useMemo(() => {
    return {
      padding: propPadding1,
      width: propWidth,
    };
  }, [propPadding1, propWidth]);

  const textColorStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const divelementskitInfobox1Style = useMemo(() => {
    return {
      height: propHeight,
      alignSelf: propAlignSelf,
      gap: propGap,
    };
  }, [propHeight, propAlignSelf, propGap]);

  const menuSeparatorStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
      flex: propFlex1,
      minWidth: propMinWidth1,
    };
  }, [propAlignSelf1, propFlex1, propMinWidth1]);

  const weTakeTheContainerStyle = useMemo(() => {
    return {
      flex: propFlex2,
      height: propHeight1,
    };
  }, [propFlex2, propHeight1]);

  return (
    <div className="column-layout" style={columnLayoutStyle}>
      <div className="divelementskit-infobox7">
        <div
          className="divelementskit-info-box-icon"
          style={divelementskitInfoBoxIconStyle}
        >
          <div className="symbol160"><img src={symbol}/></div>
        </div>
        <div className="text-color" style={textColorStyle}>
          <h3 className="heading-324">{heading3NoHiddenFees}</h3>
          <div className="nobody-likes-surprises-container">
            <span>
              <p className="nobody-likes-surprises">
                {nobodyLikesSurprisesOnThe}
              </p>
              {/* <p className="bills-so-we">{billsSoWeTakeTheTime}</p> */}
            </span>
          </div>
        </div>
      </div>
      <div
        className="divelementskit-infobox8"
        style={divelementskitInfobox1Style}
      >
        <img
          className="divelementskit-box-header-icon"
          loading="eager"
          alt=""
          src={divelementskitBoxHeader}
        />
        <div className="menu-separator" style={menuSeparatorStyle}>
          <h3 className="heading-325">{heading3PracticalAdvice}</h3>
          <div
            className="we-take-the-container"
            style={weTakeTheContainerStyle}
          >
            <span>
              <p className="we-take-the">{weTakeTheTimeToAgreeWith}</p>
              {/* <p className="you-exactly-what">{youExactlyWhatOur}</p> */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnLayout;
