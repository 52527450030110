import MainSectionSection1 from "./MainSectionSection1";
import MainSectionSection from "./MainSectionSection";
import "./FrameComponent2.css";

const FrameComponent2 = () => {
  return (
    <div className="frame-parent11" data-aos='fade-up'>
      <div className="main-section-section-parent">
        <MainSectionSection1
          symbol=""
          heading3LinkCapitalMarket="Litigation "
          theDemandAndAppetiteImpro="Our seasoned litigators have a proven track record of representing clients in complex legal disputes across various forums, striving for favorable outcomes through meticulous preparation and advocacy."
          propWidth="457px"
          propPadding="0px var(--padding-7xs) 35px 0px"
          propWidth1="46.5px"
          propMinWidth="236px"
          propLeft="9px"
        />
        <MainSectionSection1
          symbol=""
          heading3LinkCapitalMarket="Commercial Law "
          theDemandAndAppetiteImpro="We offer sound legal advice on commercial transactions, contract drafting, negotiation, and dispute resolution, safeguarding the interests of our clients at every step."
          propBackgroundImage="url('/main--section--section1@3x.png')"
          propWidth="457px"
          propPadding="0px var(--padding-7xs) 35px 0px"
          propWidth1="46.5px"
          propMinWidth="236px"
          propLeft="9px"
        />
      </div>
      <div className="main-section-section-group">
       
        <MainSectionSection
          heading3LinkBankingFinanc="Real Estate Law"
          premierTrialLawyersFromEa="Our firm provides strategic guidance on real estate transactions, property disputes, leasing agreements, and development projects, ensuring compliance with local regulations."
          // stateInTheNationWho="experience in all criminal"
          propBackgroundImage="url('/main--section--section4@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />        
        <MainSectionSection
          heading3LinkBankingFinanc="Intellectual Property"
          premierTrialLawyersFromEa="  We provide essential services for trademark registration, copyright protection, and intellectual property litigation to safeguard your innovations and creations."         
        />
         <MainSectionSection
          heading3LinkBankingFinanc="Corporate Law"
          premierTrialLawyersFromEa="Our expertise in corporate law spans from company formation and structuring to mergers and acquisitions, ensuring that businesses navigate legal frameworks seamlessly."         
        />
        <MainSectionSection
          heading3LinkBankingFinanc="Employment Law"
          premierTrialLawyersFromEa="  We provide legal counsel on employment contracts, labor disputes, and compliance matters, empowering employers and employees with the knowledge needed to navigate the intricacies of UAE labor laws."
          propBackgroundImage="url('/main--section--section3@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />
        
        <MainSectionSection
          heading3LinkBankingFinanc="Family Law"
          premierTrialLawyersFromEa=" Navigating legal matters within your family, whether concerning marriage, divorce, child custody, or inheritance, demands the right legal support. "          // stateInTheNationWho="state in the nation who"
          propBackgroundImage="url('/main--section--section3@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />
         <MainSectionSection
          heading3LinkBankingFinanc="Debt Collection"
          premierTrialLawyersFromEa=" Our aim is to collect debts swiftly and cost-effectively, benefiting both creditors and debtors."
        />
        <MainSectionSection
          heading3LinkBankingFinanc="Banking & Insurance"
          premierTrialLawyersFromEa="We specialize in providing legal guidance for clients in complex banking and finance transactions and disputes."
          // stateInTheNationWho="experience in all criminal"
          propBackgroundImage="url('/main--section--section4@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />   
       
        <MainSectionSection
          heading3LinkBankingFinanc="Maritime Law"
          premierTrialLawyersFromEa="Proficient in drafting sale and purchase agreements for maritime vessels and other commercial contracts, we excel in handling maritime disputes, including vessel arrest and seizure, maritime pollution and insurance matters."
          propBackgroundImage="url('/main--section--section3@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />
        <MainSectionSection
          heading3LinkBankingFinanc="Merger and Acquisition"
          premierTrialLawyersFromEa="Mergers and Acquisitions (M&A) law encompasses the intricate legal and regulatory aspects of corporate transactions involving company consolidation or acquisition."
          // stateInTheNationWho="experience in all criminal"
          propBackgroundImage="url('/main--section--section4@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />
        <MainSectionSection
          heading3LinkBankingFinanc="Will Registration"
          premierTrialLawyersFromEa="Will registration involves officially recording an individual’s last will and testament with a relevant authority or organization, typically a government agency or legal entity."
          // stateInTheNationWho="experience in all criminal"
          propBackgroundImage="url('/main--section--section4@3x.png')"
          propHeight="236px"
          propFlex="unset"
          propMinWidth="unset"
          propAlignSelf="stretch"
          propHeight1="unset"
        />
      </div>
    </div>
  );
};

export default FrameComponent2;
