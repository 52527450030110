import ColumnLayout from "./ColumnLayout";
import "./InputField.css";

const InputField = () => {
  return (
    <section className="input-field" data-aos='fade-up'>
      <div className="main-section3">
        <div className="icons">
          <div className="heading-326">Our Core Values</div>
          <h1 className="heading-210">A Progressive Global Law Firm</h1>
        </div>
        <div className="image-gallery">
          <ColumnLayout
            symbol="/eye.svg"
            heading3NoHiddenFees="No Hidden fees"
            nobodyLikesSurprisesOnThe="Nobody likes surprises on their bills, so we take the time."
            billsSoWeTakeTheTime=""
            divelementskitBoxHeader="/divelementskitboxheader.svg"
            heading3PracticalAdvice="Practical Advice"
            weTakeTheTimeToAgreeWith="We take the time to agree with you exactly what our legal advice entails."
            youExactlyWhatOur=""
          />
          <ColumnLayout
            symbol="/deep.svg"
            heading3NoHiddenFees="Deep Expertise"
            nobodyLikesSurprisesOnThe="Surprises on their bills, so we take the time to agree."
            // billsSoWeTakeTheTime="take the time to agree."
            divelementskitBoxHeader="/divelementskitboxheader-1.svg"
            heading3PracticalAdvice="Real client care"
            weTakeTheTimeToAgreeWith="What our work will cost before we do it not after!"
            // youExactlyWhatOur="we do it not after!"
            propFlex="0.9176"
            propPadding="10px var(--padding-10xl) 0px 0px"
            propPadding1="var(--padding-4xl) var(--padding-4xl) var(--padding-9xl)"
            propWidth="75px"
            propMinWidth="136px"
            propHeight="unset"
            propAlignSelf="stretch"
            propGap="1px"
            propAlignSelf1="unset"
            propFlex1="1"
            propMinWidth1="141px"
            propFlex2="unset"
            propHeight1="45px"
          />
          <div className="burger-menu">
            <div className="divelementskit-infobox9">
              <img
                className="divelementskit-box-header-icon1"
                loading="eager"
                alt=""
                src="/divelementskitboxheader-2.svg"
              />
              <div className="dropdown-link">
                <h3 className="heading-327">Progressive practice</h3>
                <div className="we-take-the-container1">
                  <span>
                    <p className="we-take-the1">
                    We take the time to agree with you exactly what our legal advice entails.
                    </p>
                    <p className="you-exactly-what1"></p>
                  </span>
                </div>
              </div>
            </div>
            <div className="divelementskit-infobox10">
              <div className="divelementskit-info-box-icon1">
                <div className="symbol161"><img src="/last.svg"/></div>
              </div>
              <div className="breadcrumbs">
                <h3 className="heading-328">Over 25 local offices</h3>
                <div className="likes-surprises-on-container">
                  <span>
                    <p className="likes-surprises-on">
                      Likes surprises on their bills, so
                    </p>
                    <p className="we-take-the2">we take the time.</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputField;
