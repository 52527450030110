import { useMemo } from "react";
import "./ListContainer.css";
import { Link } from "react-router-dom";

const ListContainer = ({
  heading1AboutUS,
  orderedListItemAboutUs,
  propPadding,
  propPadding1,
  propBackgroundImage,
  propPadding2,
}) => {
  const listContainerStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const section2Style = useMemo(() => {
    return {
      padding: propPadding1,
      backgroundImage: propBackgroundImage,
    };
  }, [propPadding1, propBackgroundImage]);

  const textContentStyle = useMemo(() => {
    return {
      padding: propPadding2,
    };
  }, [propPadding2]);

  return (
    <section className="list-container" style={listContainerStyle} data-aos='fade-left'>
      <div className="section33" style={section2Style}>
        <h1 className="heading-1">{heading1AboutUS}</h1>
        <div className="text-content" style={textContentStyle}>
          <div className="ordered-list"><Link to="/" className='decoration'>Home</Link></div>
          <div className="ordered-list1"> /</div>
          <div className="ordered-list2">{orderedListItemAboutUs}</div>
        </div>
      </div>
    </section>
  );
};

export default ListContainer;
