import Section3 from "./Section3";
import "./SocialMediaLinksList.css";

const SocialMediaLinksList = () => {
  return (
    <section className="social-media-links-list" data-aos='fade-up'>
      <div className="capital-markets-practice">
        <div className="bankruptcy-link">
          <div className="section-heading">Practice Areas</div>
          <h1 className="section-heading1">
            We Offer a Client Focused Approach
          </h1>
        </div>
        <div className="medical-negligence-link">
          <div className="energy-renewables-link">
            <div className="section18">
            <div className="divservice-content1">
                <div className="before2" />
                <div className="subscription-button">
                  <div className="privacy-policy-list">
                    {/* <h1 className="symbol115"></h1> */}
                    <div className="terms-of-conditions">
                      <div className="infoprivsacom-link2">
                        <h2 className="heading-39"><span><img src="1pix.svg" style={{height:'70px',padding:'5px'}}/></span>Litigation </h2>
                        <div className="the-demand-and-container5">
                          <span>
                            <p className="the-demand-and5">
                            Experienced attorneys navigate disputes, providing strategic counsel to safeguard clients' interests.
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="icon-arrow">
                      <a href="/our-services"> <img className="link-icon3"loading="eager" alt="" src="/link.svg"/></a>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section19">
              <div className="divservice-content2">
                <div className="before3" />
                <div className="divservice-content-inner">
                  <div className="symbol-parent13">
                    {/* <h1 className="symbol116"></h1> */}
                    <div className="heading-3-link-bankruptcy-parent">
                      <h2 className="heading-310"><span><img src="2pix.svg" style={{height:'70px',padding:'5px'}}/></span>Commercial Law</h2>
                      <div className="the-national-trial-lawyers-to-parent">
                        <div className="the-national-trial-container">
                          <span>
                            <p className="the-national-trial">
                            We offer sound legal advice on commercial transactions, contract drafting, negotiation, and dispute resolution.
                            </p>
                          </span>
                        </div>
                        <div className="icon-arrow">
                        <a href="/our-services">  <img className="link-icon4"loading="eager" alt="" src="/link.svg"/></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-symbol">
            <Section3 heading3LinkBankingFinanc={`Real Estate Law`} />
            {/* <Section3
              heading3LinkBankingFinanc="TAX Consultancy"
              propBackgroundImage="url('/section3@3x.png')"
            /> */}
            {/* <div className="section20">
              <div className="divservice-content3">
                <div className="before4" />
                <div className="divservice-content-child">
                  <div className="frame-parent6">
                    <div className="heading-3-link-medical-neg-parent">
                      <b className="heading-311">Medical Negligence</b>
                      <div className="he-applies-his-container">
                        <span>
                          <p className="he-applies-his">
                            He applies his expertise, skill, and
                          </p>
                          <p className="experience-in-all">
                            experience in all criminal
                          </p>
                        </span>
                      </div>
                    </div>
                    <img className="link-icon5" alt="" src="/link.svg" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <a className="our-services-link"href="our-services"><div className="section-link">
        <div className="intellectual-property-symbol">
          <div className="useful-links-frame">
            <div className="view-all-practice">{`view all practice area `}</div>
            <div className="symbol117"><img src="/Symbol6.svg"/></div>
          </div>
        </div>
        <div className="after" />
      </div></a>
    </section>
  );
};

export default SocialMediaLinksList;
