import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;


export const fetchLegalUpdates = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/legalupdates`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching legal updates:', error);
    throw error; 
  }
};


export const fetchLegalUpdateById = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/api/legalupdates/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching legal update with ID ${id}:`, error);
    throw error; 
  }
};


export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/events`);
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error; 
  }
};


export const fetchEventById = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/api/events/${id}`);
    return response.data; 
  } catch (error) {
    console.error(`Error fetching event with ID ${id}:`, error);
    throw error; 
  }
};


export const fetchPublications = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/publications`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching publications:', error);
    throw error; 
  }
};


export const fetchPublicationById = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/api/publications/${id}`);
    return response.data; 
  } catch (error) {
    console.error(`Error fetching publication with ID ${id}:`, error);
    throw error; 
  }
};

export const submitForm2 = async (formData) => {
  try {
    const response = await axios.post(`${baseURL}/api/submit-form2`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const submitForm1 = async (formData) => {
  try {
    const response = await axios.post(`${baseURL}/api/submit-form1`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};