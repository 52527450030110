import { useMemo } from "react";
import "./DivelementorWidgetContainer.css";

const DivelementorWidgetContainer = ({
  featureIcon3png,
  heading3409,
  qualifiedLawyers,
  propPadding,
  propBorderRight,
  propBorderRadius,
  propWidth,
}) => {
  const divelementskitInfoboxStyle = useMemo(() => {
    return {
      padding: propPadding,
      borderRight: propBorderRight,
      borderRadius: propBorderRadius,
    };
  }, [propPadding, propBorderRight, propBorderRadius]);

  const featureIcon3pngStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="divelementor-widget-container3">
      <div
        className="divelementskit-infobox4"
        style={divelementskitInfoboxStyle}
      >
        <div className="list1">
          <img
            className="feature-icon-3png1"
            loading="eager"
            alt=""
            src={featureIcon3png}
            style={featureIcon3pngStyle}
          />
        </div>
        <div className="list2">
          <b className="heading-312">{heading3409}</b>
        </div>
        <div className="qualified-lawyers2">{qualifiedLawyers}</div>
      </div>
    </div>
  );
};

export default DivelementorWidgetContainer;
