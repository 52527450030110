import React, { useState, useEffect } from "react";
import "./Section4.css";

const images = [
  '/uae1.jpg',
  '/ksa2.jpg',
  '/aboutus.jpg'
];

const Section4 = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="section13">
      <div className="divbanner-item" data-aos="fade-up">
        {images.map((image, index) => (
          <div
            key={index}
            className={`image-container ${index === currentImageIndex ? 'visible' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
        <div className="symbol111" style={{ visibility: "hidden" }}>
          <div className="list">
            <div className="button-go-to-slide-1" />
            <div className="button-go-to-slide-2" />
            <div className="button-go-to-slide-3" />
          </div>
        </div>
        <div className="divcol-md-4" />
        <div className="section14">
          <div className="frame-parent5">
            <div className="before-parent">

              {/* <div className="before" /> */}
              <div className="button7">
                {/* <h3 className="dubais-top-rated">Dubai's Top Tier Law Firm</h3> */}

                <h1 className="heading-1-container">
                  <span>
                    <p className="reliable-legal">Al Jneibi & </p>
                    <p className="partner">Partners</p>
                  </span>
                </h1>
              </div>
            </div>
            <div className="over-200-years">{`Over Decades of Collective Expertise & Millions Recovered for Our Clients`}</div>
          </div>
          <a className="link1" href="/contact-us">
            <div className="symbol112"><img src="/Symbol6.svg" alt="Symbol" /></div>
            <div className="get-a-free"> get a free case review</div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section4;


// import "./Section4.css";

// const Section4 = () => {

//   return (

//     <section className="section13">

//       <div className="divbanner-item" data-aos='fade-up'>

//         <div className="symbol111" style={{ visibility: "hidden" }}>
//           <div className="list">
//             <div className="button-go-to-slide-1" />
//             <div className="button-go-to-slide-2" />
//             <div className="button-go-to-slide-3" />
//           </div>
//         </div>
//         <div className="divcol-md-4" />
//         <div className="section14">
//           <div className="frame-parent5">
//             <div className="before-parent">
//               {/* <div className="before" /> */}
//               <div className="button7">
//                 {/* <h3 className="dubais-top-rated">Dubai's Top Tier Law Firm</h3> */}
//                 <h1 className="heading-1-container">
//                   <span>
//                     <p className="reliable-legal">Al Jneibi &</p>
//                     <p className="partner">Partners</p>
//                   </span>
//                 </h1>
//               </div>
//             </div>
//             <div className="over-200-years">{`Over Decades of Collective Expertise & Millions Recovered for Our Clients`}</div>
//           </div>
//           <a className="link1" href="/contact-us">
//             <div className="symbol112"><img src="/Symbol6.svg" /></div>
//             <div className="get-a-free"> get a free case review</div>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Section4;



// import React, { useState, useEffect } from 'react';
// import "./Section4.css";

// const Section4 = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentIndex(prevIndex => (prevIndex + 1) % 3); // Assuming there are 3 .frame-parent5 divs
//     }, 8000); // Change the slide every 8 seconds

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, []);

//   return (
//     <section className="section13">
//       <div className="divbanner-item">
//         <div className="divcol-md-4" />
//         <div className="section14">
//           <div className="frame-parent5" style={{ opacity: currentIndex === 0 ? 1 : 0 }}>
//                         <div className="before-parent">
//               <div className="before" />
//               <div className="button7">
//                 <h3 className="dubais-top-rated">Dubai's Top Rated Lawyers</h3>
//                 <h1 className="heading-1-container">
//                   <span>
//                     <p className="reliable-legal">Al jneibi </p>
//                     <p className="partner">& Partners</p>
//                   </span>
//                 </h1>
//               </div>
//             </div>
//             <div className="over-200-years">{`Over 200 Years of Combined Experience & 1 Billion Recovered for Our Clients.`}</div>

//           </div>
//           <div className="frame-parent5" style={{ opacity: currentIndex === 1 ? 1 : 0 }}>
//             {/* Content for the second frame-parent5 */}
//           </div>
//           <div className="frame-parent5" style={{ opacity: currentIndex === 2 ? 1 : 0 }}>
//             {/* Content for the third frame-parent5 */}
//           </div>
//           <a className="link1" href="/contact-us">
//             <div className="symbol112"></div>
//             <div className="get-a-free"> get a free case review</div>
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Section4;
