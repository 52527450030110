import { useMemo } from "react";
import "./DivelementorWidgetWrap2.css";

const DivelementorWidgetWrap2 = ({
  team6png,
  heading3LinkKimballJones,
  litigationAssociate,
  propMarginLeft,
  propPadding,
  description,
}) => {
  const divelementorWidgetWrapStyle = useMemo(() => {
    return {
      marginLeft: propMarginLeft,
      padding: propPadding,
    };
  }, [propMarginLeft, propPadding]);

  return (
    <div
      className="divelementor-widget-wrap8"
      style={divelementorWidgetWrapStyle}
    >
      {/* <img className="team-6png-icon2" loading="eager" alt="" src={team6png} /> */}
      <div className="infoprivsacom-link4">
        <h3 className="heading-336">{heading3LinkKimballJones}</h3>
        <div className="litigation-associate3">{litigationAssociate}</div>
        <p className="description"><span> <img  className="mail" src="/Symbol05.svg" /></span>{description}</p> {/* Added description here */}
      </div>
    </div>
  );
};


export default DivelementorWidgetWrap2;
