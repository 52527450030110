import "./Bankruptcy.css";

const Bankruptcy = () => {
  return (
    <section className="bankruptcy1" >
      <div className="section15">
        <div className="sub-section-frame" data-aos='fade-up'>
          <div className="divelementor-widget-container">
          <div className="divelementor-widget-container2">
            <div className="divelementskit-infobox3">
              <img
                className="image-11-icon "
                loading="eager"
                alt="legal"
                src="/image20.png"
              />
                 <div class="text-overlay">
                  <img  src='/feature-icon-2png@2x.svg' alt="legal counsel" style={{height:'70px',padding:'5px'}}/>
    <h3 clasNames="heading-37">Expert Legal Counsel</h3>
    <div className="wrote-multiple-books-container">
      <p className="wrote-multiple-books pt-2">Tailored solutions for your legal needs.</p>
      {/* <p class="fighting-traffic-tickets">
        fighting traffic tickets
      </p> */}
    </div></div>
            </div>
          </div>
          </div>
          <div className="divelementor-widget-container fade-in-out">
            <div className="divelementskit-infobox1">
              <img
                className="image-8-icon "
                loading="eager"
                alt=""
                src="/image-8@2x.png"
              />
                 <div class="text-overlay">
                 <img src='/feature-icon-4png@2x.svg' alt="legal counsel" style={{height:'70px',padding:'5px'}}/>
    <h3 class="heading-37">Personalized Legal Advice</h3>
    <div class="wrote-multiple-books-container">
      <p class="wrote-multiple-books pt-2">Customized guidance for your unique situation</p>
      {/* <p class="fighting-traffic-tickets">
        fighting traffic tickets
      </p> */}
    </div></div>
            </div>
          </div>
          <div className="divelementor-widget-container1">
            <div className="divelementskit-infobox2">
              <img
                className="image-10-icon"
                loading="eager"
                alt=""
                src="/image-10@2x.png"
              />
                <div class="text-overlay">
                <img src='/feature-icon-1png@2x.svg' alt="legal-counsel" style={{height:'70px',padding:'5px'}}/>

    <h3 class="heading-37">Trusted Advocates</h3>
    <div class="wrote-multiple-books-container">
      <p class="wrote-multiple-books pt-2">Dedicated to protecting your rights.</p>
      {/* <p class="fighting-traffic-tickets">
        fighting traffic tickets
      </p> */}
    </div></div>
            </div>
          </div>
          <div className="divelementor-widget-container2">
            <div className="divelementskit-infobox3">
              <img
                className="image-11-icon"
                loading="eager"
                alt=""
                src="/image-11@2x.png"
              />
                 <div class="text-overlay">
                 <img src='/feature-icon-3png@2x.svg' alt="legal-counsel" style={{height:'70px',padding:'5px'}}/>

    <h3 class="heading-37">Compassionate Support</h3>
    <div class="wrote-multiple-books-container">
      <p class="wrote-multiple-books pt-2">Guidance through life's legal challenges</p>
      {/* <p class="fighting-traffic-tickets">
        fighting traffic tickets
      </p> */}
    </div></div>
            </div>
          </div>
        </div>
        <div className="bankruptcy-frame" data-aos='fade-up'>
          <div className="banking-finance-frame">
            <div className="tax-consultancy-frame pe-2">
              <div className="top-rated-lawyers">Top Rated Lawyers in Dubai</div>
              <h1 className="new-york-city-container">
                <span>
                  <p className="new-york-city">Your Reliable <br/> Legal Partners </p>
                  {/* <p className="personal-injury">Personal Injury</p>
                  <p className="attorney">Attorney</p> */}
                </span>
              </h1>
            </div>
            <div className="intellectual-property-frame">
              <h3 className="privsa-law-offices1">
                Al Jneibi Law Offices are more than a law firm.
              </h3>
              <div className="practice-areas-list">
                <div className="company-link">
                  <div className="hile-its-true-container1">
                    <span>
                      <p className="hile-its-true1">
                      Welcome to Al Jneibi & Partners, your trusted partner for legal needs as you face infringement of your legal rights and looking forward for the administration of Justice. Our firm is dedicated to providing unmatched legal services, guided by seasoned attorneys and a commitment to client satisfaction, we deliver the best possible legal solutions for you.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="for-most-of-container1">
                <span>
                  {/* <p className="for-most-of1">
                    For most of our clients, we meet them when the worst thing
                    that ever could happen to them has happened. We know that they want more
                    than an attorney; they want someone who will listen attentively, treat them
                    with respect.
                  </p> */}
                </span>
              </div>
            </div>
          </div>
          <div className="section16">
            <div className="section-title1">
              <img src='/Symbol4.svg'/>
              <div className="list-item97">+971543000775</div>
            </div>
            <div className="section-title1">
              <h3 className="symbol114"></h3>
              <img src="/Symbol05.svg" /><h1 className="list-item98">info@ajplawyers.ae</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bankruptcy;
