import DivelementorWidgetWrap from "./DivelementorWidgetWrap";
import FrameComponent3 from "./FrameComponent3";
import "./Section1.css";

const Section1 = () => {
  return (
    <section className="section24" data-aos='fade-up'>
      {/* <FrameComponent3/> */}
      {/* <div className="list6">
        <div className="heading-320">Our Team</div>
        <div className="list7">
          <h1 className="heading-28">Meet the Attorneys</h1>
          <div className="list8">
            <div className="link7">
              <div className="heading4">
                <div className="symbol127">
                  <div className="view-all">{`View All `}</div>
                  <div className="symbol128"></div>
                </div>
              </div>
              <div className="after1" />
            </div>
          </div>
        </div>
      </div>
      <div className="section25">
        <DivelementorWidgetWrap
          team6png="/team6png@2x.png"
          heading3LinkKimballJones="Kimball Jones"
          litigationAssociate="Litigation Associate"
        />
        <DivelementorWidgetWrap
          team6png="/team1png@2x.png"
          heading3LinkKimballJones="Craig Salazar"
          litigationAssociate="Senior Associate"
          propWidth="32px"
          propPadding="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf="stretch"
          propPadding1="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf1="stretch"
          propPadding2="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf2="stretch"
        />
        <DivelementorWidgetWrap
          team6png="/team4png@2x.png"
          heading3LinkKimballJones="Nathan Morris"
          litigationAssociate="Litigation Associate"
          propWidth="32px"
          propPadding="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf="stretch"
          propPadding1="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf1="stretch"
          propPadding2="var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)"
          propAlignSelf2="stretch"
        />
      </div> */}
    </section>
  );
};

export default Section1;
