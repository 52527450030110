import DivelementorWidgetWrap2 from "./DivelementorWidgetWrap2";
import "./FrameComponent3.css";

const FrameComponent3 = () => {
  return (
    <section className="useful-links-container-parent">
      <div className="useful-links-container">
        <div className="main-section4" style={{textAlign: 'center', fontSize: '24px'}}>Our Team</div>
        <h1 className="main-section5">Meet the Lawyers</h1>
      </div>
      <div className="terms-of-conditions-link1">
       <DivelementorWidgetWrap2
          heading3LinkKimballJones="Adel Al Jneibi"
          litigationAssociate="Founder Partner / Advocate"
          description="a.jneibi@ajplawyers.ae"
        />
        <DivelementorWidgetWrap2
          heading3LinkKimballJones="Motaz Khalil"
          litigationAssociate="Senior Partner - Dispute Resolution"
          description="m.khali@ajplawyers.ae"
        />
        <DivelementorWidgetWrap2
          heading3LinkKimballJones="Ahmed Sirhan"
          litigationAssociate="Managing Partner"
          description="a.sirhan@ajplawyers.ae"
        />
        <DivelementorWidgetWrap2
          heading3LinkKimballJones="Taha Sharaf"
          litigationAssociate="Partner - Dispute Resolution"
          description="sharaf@ajplawyers.ae"
        />
        <DivelementorWidgetWrap2
          heading3LinkKimballJones="Baseer Aikapadath"
          litigationAssociate="Legal Advisor"
          description=""
        />
      </div>
    </section>
  );
};

export default FrameComponent3;
