import "./SubscribeInputButton.css";

const SubscribeInputButton = () => {
  return (
    <></>
//     <div className="subscribe-input-button">
//       <h3 className="heading-416">Head Office</h3>
//       <div className="privacy-policy-list1">
//         <div className="symbol-symbol-frame">
//           <div className="symbol150"><img src='/Symbol02.svg'/></div>
//           <div className="frame-within-frame">
//             <div className="symbol151"><img src='/Symbol9.svg'/></div>
//             <div className="symbol152"><img src='/Symbol0.svg'/></div>
//           </div>
//         </div>
//         <div className="infoprivsacom-link-list">
//           <div className="list-item-container6">
//             <p className="little-william-st5">Office No.204, Wasal Business Central, Port Saeed, Deira, Dubai-UAE

// </p>
//             <p className="vic-3000-australia5"></p>
//           </div>
//           <div className="list-item118">+97137376611</div>
//           <div className="list-item119">info@aljneibi.com</div>
//         </div>
//       </div>
//       <b className="heading-417">Get in Touch</b>
//       <div className="input-email-button-symbol-fram">
//         {/* <div className="input8">
//           <div className="email7">{`Email `}</div>
//         </div> */}
//         {/* <input className="input8" placeholder="Email" />
//         <div className="button9">
//           <div className="symbol153"><img src="/Symbol-16.svg"/></div>
//         </div> */}
//          <a href="/contact-us" class="contact-link">
//     <button class="contact-button">Contact Us <img src="/Symbol6.svg"/></button>
//   </a>
//       </div>
//     </div>
  );
};

export default SubscribeInputButton;
