import NewParentName from "../components/NewParentName";
import DivelementorWidgetWrap1 from "../components/DivelementorWidgetWrap1";
import "./OurServicesDetailPage.css";
import FrameComponent from "../components/FrameComponent";
import SubscribeInputButton from "../components/SubscribeInputButton";
import Section from "../components/Section";
import { Navbar } from "react-bootstrap";

const OurServicesDetailPage = () => {
  return (
    <>
    <div className="our-services-detail-page" >
      <Navbar ColorService={'#e86500'}/>
      <section className="team-card-wrapper" >
        <div className="team-card">
          <div className="heading1">
            <div className="button">
              <div className="symbol1">
                <img
                  className="case-1jpg-icon"
                  loading="eager"
                  alt=""
                  src="/case-1jpg@2x.png"
                />
              </div>
              <div className="links-container1">
                <div className="symbol2">
                  <div className="heading2">
                    <div className="section1">
                      <div className="irms-culture-of-container">
                        <span>
                          <p className="irms-culture-of">
                            irm’s culture of supporting our clients is very
                            important to us and we work hard to
                          </p>
                          <p className="nurture-and-develop">
                            nurture and develop it. We will go on a journey with
                            you, building mutual trust and
                          </p>
                          <p className="respect-as-we">
                            respect as we tackle all obstacles. The attorneys
                            that practice in our law firm's
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="securities-law-practice">
                      securities law practice group
                    </div>
                  </div>
                  <div className="button1">
                    <div className="anybody-who-suspects-container">
                      <span>
                        <p className="anybody-who-suspects">
                          Anybody who suspects child abuse has a legal
                        </p>
                        <p className="obligation-to-report">
                          obligation to report their concerns…
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="heading-2-container">
                    <span>
                      <p className="how-a-data">
                        How a Data Protection Attorney Can Help
                      </p>
                      <p className="you-get-charges">
                        you Get Charges Dismissed
                      </p>
                    </span>
                  </div>
                  <div className="at-our-law-container">
                    <span>
                      <p className="at-our-law">
                        At our law firm the securities attorneys understand that
                        there is a difference between simply
                      </p>
                      <p className="assisting-a-client">
                        assisting a client with drafting offering and disclosure
                        documents and helping to guide the
                      </p>
                      <p className="business-owners-and">
                        business owners and other key individuals.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="associate-senior">
                <div className="frame-teampng">
                  <div className="divelementor-widget-wrap3">
                    <div className="divelementor-background-overl" />
                    <img
                      className="link-video-popup"
                      loading="eager"
                      alt=""
                      src="/link--videopopup.svg"
                    />
                  </div>
                  <div className="attorneys-list">
                    <div className="practice-areas">
                      <div className="careers">
                        <div className="symbol3"></div>
                        <div className="q-a">
                          <div className="symbol4"></div>
                          <div className="symbol5"></div>
                          <div className="symbol6"></div>
                          <div className="symbol7"></div>
                        </div>
                      </div>
                    </div>
                    <div className="parent-useful-links">
                      <div className="list-item-link">
                        <div className="item-registration-container">
                          <span>
                            <p className="registration-of-records">
                              Registration of records that contain
                            </p>
                            <p className="personal-data">personal data.</p>
                          </span>
                        </div>
                      </div>
                      <div className="item-data">
                        Data transfer approval procedures.
                      </div>
                      <div className="item-general">
                        General Data Protection compliance.
                      </div>
                      <div className="item-video">Video surveillance.</div>
                      <div className="item-data1">Data retention</div>
                    </div>
                  </div>
                </div>
                <div className="input-email">
                  <div className="opportunities-and-relationship-container">
                    <span>
                      <p className="opportunities-and-relationship">
                        Opportunities and relationships that can help them to
                        achieve their goals; thus our attorneys are
                      </p>
                      <p className="able-to-leverage">
                        able to leverage their knowledge and contacts to
                        identify people and businesses that can help
                      </p>
                      <p className="clients-to-grow">
                        clients to grow their businesses.
                      </p>
                    </span>
                  </div>
                  <div className="heading-21">
                    Specializing Attorneys on this Area
                  </div>
                  <div className="section2">
                    <DivelementorWidgetWrap1
                      team6png="/team6png1@2x.png"
                      heading3LinkKimballJones="Kimball Jones"
                      litigationAssociate="Litigation Associate"
                    />
                    <DivelementorWidgetWrap1
                      team6png="/team2png@2x.png"
                      heading3LinkKimballJones="David D. Stewart"
                      litigationAssociate="Senior Associate"
                    />
                    <div className="frame-nathan-morris">
                      <div className="divelementor-widget-wrap4">
                        <img
                          className="team-4png-icon"
                          loading="eager"
                          alt=""
                          src="/team4png1@2x.png"
                        />
                        <div className="heading-3-link-nathan-morr-parent">
                          <b className="heading-35">Nathan Morris</b>
                          <div className="litigation-associate">
                            Litigation Associate
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divelementskit-card-parent">
                <div className="divelementskit-card">
                  <div className="link">
                    <img
                      className="divekit-accordion-icon-left-g"
                      alt=""
                      src="/divekit-accordion-icon-left-group.svg"
                    />
                    <div className="how-many-applications">
                      How many applications do you receive?
                    </div>
                  </div>
                  <div className="our-securities-lawyers-can-bet-wrapper">
                    <div className="our-securities-lawyers-container">
                      <span>
                        <p className="our-securities-lawyers">
                          our securities lawyers can better advise each client
                          as well as to help ensure
                        </p>
                        <p className="that-any-disclosure">
                          that any disclosure documents are accurate and
                          complete at our law firm the
                        </p>
                        <p className="securities-attorneys-understan">
                          securities attorneys understand that there is a
                          difference.
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="divelementskit-card1">
                  <img
                    className="link-icon"
                    loading="eager"
                    alt=""
                    src="/link1.svg"
                  />
                  <div className="link-when">
                    When can I expect to hear if I have been admitted?
                  </div>
                </div>
                <div className="divelementskit-card2">
                  <img className="link-icon1" alt="" src="/link1.svg" />
                  <div className="link-what">
                    What financial assistance is available to me?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="when-can-expect-hear-frame">
            <div className="contact-us-form">
              <div className="practice-area-list">
                <b className="practice-area">Practice Area:</b>
              </div>
              <div className="list-item-container">
                <span>
                <p className="capital-markets">Corporate Law</p>
                  <p className="bankruptcy">Commercial Law</p>
                  <p className="banking-finance">{`Real Estate Law`}</p>
                  <p className="tax-consultancy">TAX Consultancy</p>
                  <p className="medical-negligence">Medical Negligence</p>
                  <p className="energy-renewables">{`Energy & Renewables`}</p>
                  <p className="intellectual-property">Intellectual Property</p>
                </span>
              </div>
            </div>
            <div className="section3">
              <div className="divelementor-background-overl1" />
              <div className="heading-2-need-help-with-any-wrapper">
                <b className="heading-2-container1">
                  <span>
                    <p className="need-help-with">Need help with any</p>
                    <p className="legal-issues">legal issues?</p>
                  </span>
                </b>
              </div>
              <div className="form-section-section">
                <div className="symbol8"></div>
              </div>
              <div className="form-section-section-inp-parent">
                <div className="form-section-section-inp">
                  <div className="form-section-section1">
                    <div className="symbol9"></div>
                  </div>
                  <div className="full-name-wrapper">
                    <div className="full-name">{`Full name `}</div>
                  </div>
                </div>
                <div className="form-section-section-inp1">
                  <div className="email">{`Email `}</div>
                </div>
              </div>
              <div className="input-form-section">
                <div className="form-section-section-but">
                  <div className="symbol10"></div>
                  <div className="contact-us">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
    </div>
         <footer className="section">
         <FrameComponent/>
         <SubscribeInputButton />
       </footer>
       <Section/>
       </>
  );
};

export default OurServicesDetailPage;
