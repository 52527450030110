import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ListContainer from '../components/ListContainer';
import FrameComponent4 from '../components/FrameComponent4';
import NewsRight from '../components/EventsRight';
import NewsLeft from '../components/EventsLeft';
// import './Publications.css';
import PublicationsLeft from '../components/PublicationsLeft';
import PublicationsRight from '../components/PublicationsRight';
import Section from '../components/Section';

function Publications() {
  return (
    <>
      <Navbar ColorPublications={'#e86500'} />
      <ListContainer 
        heading1AboutUS="Publications"
        orderedListItemAboutUs="Publications"
        propBackgroundImage="url('/publications.jpg')"
      />
      <section className="practice-area-capital-markets" >
        <div className="left-column">
          <PublicationsLeft />
        </div>
        <div className="right-column">
          <PublicationsRight />
        </div>
      </section>
      <Footer />
      <Section/>
    </>
  );
}

export default Publications;
