import Navbar from "../components/Navbar";
import FrameComponent9 from "../components/FrameComponent9";
import "./ContactUs.css";
import Section from "../components/Section";
import SubscribeInputButton from "../components/SubscribeInputButton";
import FrameComponent from "../components/FrameComponent";
import ListContainer from "../components/ListContainer";

const ContactUs = () => {
  return (
    <>
    <div className="contact-us1">
      <Navbar
        heading1Defective3MEarplu="Contact Us"
        orderedListItemLitigation="Contact Us"
        listItemColor="#fff"
        linkNavsearchButtonColor="#eb934f"
        heading1Padding="var(--padding-184xl) var(--padding-xl) var(--padding-185xl)"
        listItemDepositionsRevealWidth="unset"
        listItemDepositionsRevealDisplay="inline-block"
        orderedListWidth="unset"
        propFlex="unset"
        ColorContact={'#e86500'}
      />
         <ListContainer 
        heading1AboutUS="Contact Us"
        orderedListItemAboutUs="Contact Us"
        propBackgroundImage="url('/contact.jpg')"
      />
      <FrameComponent9 />
   
    </div>
     <FrameComponent />
     <Section/>
     </>
  );
};

export default ContactUs;
