import './FrameComponent2.0.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import "./FrameComponent4.css";
const FrameComponent = () => {
  const spacing=2;
  return (

    <footer className='section' data-aos='fade-up' >
      <div className='container'>
        <div className='innerDivStyle' >
          <div className="heading-parent">
            <div className="heading8">
              <div className="heading-29">About Us</div>
              <div className="the-demand-and-container6">
                <span>
                  <p className="the-demand-and6">From corporations to individuals, ALJNEIBI & PARTNERS provides expert legal guidance. Contact us for tailored assistance today. </p>
                </span>
              </div>
            </div>
            <div className="frame-wrapper1">
              <div className="item-link-facebook-group">
                <img
                  className="item-link-facebook5"
                  loading="eager"
                  alt=""
                  src="/item--link--facebook.svg"
                />
                <img
                  className="item-link-twitter6"
                  alt=""
                  src="/item--link--twitter-1.svg"
                />
                <img
                  className="item-link-linkedin5"
                  loading="eager"
                  alt=""
                  src="/item--link--linkedin.svg"
                />
                {/* <img
                  className="item-link-instagram5"
                  loading="eager"
                  alt=""
                  src="/item--link--instagram.svg"
                /> */}
              </div>
            </div>
          </div>

          <div className='innerDivStyle5'>
           <div className="useful-links-frame2">
            <div className="attorneys-list-frame ">
              <div className='branches-add' style={{ marginBottom:"20px"}}>             
                <span className="symbol144 add-margin">
                 {/* <img src='/Symbol.svg' /> */}
                 <a href='/contact-us' className="anchor-decor">Besides the UAE, we are also practicing in the following countries:</a>
                </span>
              </div>
              <div className='branches-add'style={{ marginBottom:"20px"}}>
                <span className="symbol144 add-margin">
                <img src='/Symbol.svg' />
                <a href='/contact-us' className="anchor-decor">&nbsp;Egypt (Cairo)</a>
                </span>
              </div>    
              <div className='branches-add'style={{ marginBottom:"20px"}}>
                <span className="symbol144 add-margin">
                <img src='/Symbol.svg' />
                <a href='/contact-us' className="anchor-decor">&nbsp;KSA</a>
                </span>
              </div>   
              <div className='branches-add' style={{ marginBottom:"20px"}}>
                <span className="symbol144 add-margin">
                <img src='/Symbol.svg' />
                <a href='/contact-us' className="anchor-decor">&nbsp;Palestine</a>
                </span>
              </div>   
              <div className='branches-add' style={{ marginBottom:"20px"}}>
                <span className="symbol144 add-margin">
                <img src='/Symbol.svg' />
                <a href='/contact-us' className="anchor-decor">&nbsp;Jerusalem </a>
                </span>
              </div>   
              <div className='branches-add'>
                <span className="symbol144 add-margin">
                <img src='/Symbol.svg' />
                <a href='/contact-us' className="anchor-decor">&nbsp;Syria</a>
                </span>
              </div>        
            </div>
           </div>
          </div>

        </div>
       
  
        <div className='innerDivStyle'><div className="heading-4-useful-links-container" style={{ marginBottom: '20px' }}>
          <div className="heading-415">Practice Areas</div>
          <div className="useful-links-frame1">
            {/* <div className="symbol-frame-list">
              <div className="symbol144 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol145 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol147 add-margin"><img src='/Symbol.svg' />
              </div>
              <div className="symbol148 add-margin"><img src='/Symbol.svg' /></div>
              <div className="symbol149 add-margin"><img src='/Symbol.svg' /></div>
            </div> */}
            <div className="attorneys-list-frame">
              <div className="list-item112 add-margin">
                <a href='/our-services' className="anchor-decor "><img src='/Symbol.svg' />&nbsp;Litigation </a>
              </div>
              <div className="list-item113 add-margin"><a href='/our-services' className="anchor-decor"><img src='/Symbol.svg' />&nbsp;Commercial Law </a></div>
              <div className="list-item114 add-margin"><a href='/our-services' className="anchor-decor"><img src='/Symbol.svg' />&nbsp;Real Estate Law</a></div>
              <div className="list-item115 add-margin"><a href='/our-services' className="anchor-decor"><img src='/Symbol.svg' />&nbsp;Intellectual property</a></div>
              <div className="list-item116 add-margin"><a href='/our-services' className="anchor-decor"><img src='/Symbol.svg' />&nbsp;Corporate Law</a></div>
              <div className="list-item117 add-margin"><a href='/our-services' className="anchor-decor"><img src='/Symbol.svg' />&nbsp;Employment Law</a></div>
            </div>
          </div>
        </div></div>
        {/* <div className='innerDivStyle'><div className="heading-4-useful-links-container" style={{ marginBottom: '20px' }}>
          <div className="heading-415">Branches</div>
          <div className="useful-links-frame2">
            <div className="symbol-frame-list">
              <div className="symbol144 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol145 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
              <div className="symbol146 add-margin">
                <img src='/Symbol.svg' />
              </div>
            </div>
            <div className="attorneys-list-frame ">
              <div className="list-item112 add-margin">
                <a href='/contact-us' className="anchor-decor">Dubai</a>
              </div>
              <div className="list-item113 add-margin">
                <a href='/contact-us' className="anchor-decor">Abu Dhabi</a>
              </div>
              <div className="list-item114 add-margin">
                <a href='/contact-us' className="anchor-decor">Ras Al-Khaimah</a>
              </div>
              <div className="list-item114 add-margin">
                <a href='/contact-us' className="anchor-decor">Al Ain</a>
              </div>
              <div className="list-item114 add-margin">
                <a href='/contact-us' className="anchor-decor">Egypt</a>
              </div>
              <div className="list-item114 add-margin">
                <a href='/contact-us' className="anchor-decor">KSA</a>
              </div>
              <div className="list-item114 add-margin">
                <a href='/contact-us' className="anchor-decor">Syria</a>
              </div>
            </div>
          </div>
        </div></div> */}
        <div className='innerDivStyle2'>
          <div className="subscribe-input-button">
            <div className="heading-415 margin-right" style={{ fontsize: '100px' }}>Head Office</div>
            <div className="privacy-policy-list1">
              <div className="symbol-symbol-frame">
                <div className="symbol150"><FontAwesomeIcon icon={faLocationDot} style={{ color: "#e86500", fontSize: "30px" }} /></div>
                <div className="frame-within-frame">
                  <div className="symbol151"><FontAwesomeIcon icon={faPhone} style={{ color: "#e86500", fontSize: "20px" }} /></div>
                  <div className="symbol152"><FontAwesomeIcon icon={faEnvelope} style={{ color: "#e86500", fontSize: "20px" }} /></div>
                </div>
              </div>
              <div className="infoprivsacom-link-list">
                <div className="list-item-container6">
                  <p className="little-william-st5 font-color-fix" style={{ fontWeight: '300', letterSpacing: '1px' }}>Office No.204, Wasl Business Central, Port Saeed, Deira, Dubai</p>
                  <p className="vic-3000-australia5"></p>
                </div>
                <div className="list-item118" style={{ textAlign: 'start', fontWeight: '300', letterSpacing: '1px' }}> +971543000775, +97142551525</div>
                <div className="list-item119" style={{ textAlign: 'start', fontWeight: '300', letterSpacing: '1px' }}>info@ajplawyers.ae</div>

              </div>
            </div>
            {/* <b className="heading-417">Subscribe</b>
            <div className="input-email-button-symbol-fram">
              <div className="input8">
                <div className="email7">{`Email `}</div>
              </div>
              <div className="button9">
                <div className="symbol153"></div>
              </div>
            </div> */}
            <div className="input-form-section" style={{display:'flex', flexDirection:'column'}}>
              <h5 style={{fontWeight:'bold'}}>Get In Touch</h5>
              <a href='/contact-us'className="contact-btn mt-2" style={{ zIndex: '2', color: 'white', textDecoration: 'none' }} > <div className="form-section-section-but">
                <div className="contact-us">Contact Us</div>
              </div></a>
            </div>
          </div>
        </div>
      </div>
    </footer>);
};

export default FrameComponent;
