import Navbar from "../components/Navbar";
import "./Blogs.css";
import FrameComponent from "../components/FrameComponent";
import Section from "../components/Section";
import ListContainer from "../components/ListContainer";
import './Careers.css'

const Careers = () => {
  return (
    <>
      <div className="careers-page">
        <Navbar
          heading1Defective3MEarplu="Careers"
          orderedListItemLitigation="Careers"
          ColorCareers={"#e86500"}
        />
        <ListContainer
          heading1AboutUS="Careers"
          orderedListItemAboutUs="Careers"
          propBackgroundImage="url('/legalupdates-careers.jpg')"
        />
        <div class="careers-content" >
          <div class="section-title text-center">
            <h5 class="tagline section-title__tagline--has-dots">
              Join Al Jneibi &amp; Partners
            </h5>
          
            <p class="description">
              We are always looking for talented individuals to join our team.
            </p>
            <p class="section-description">
              Send your CV to{" "}
              <a href="mailto:hr@ajplawyers.ae">hr@ajplawyers.ae</a>
            </p>
            <div className="card">
              <div className="line"></div>
            <p class="section-descrip">
              At Al Jneibi &amp; Partners, we offer:
            </p>
            <ul class="benefits-list">
              <li>Competitive salaries</li>
              <li>Opportunities for career growth and advancement</li>
              <li>A supportive and collaborative work environment</li>
              <li>Continuing education and professional development</li>
              <li>Work-life balance initiatives</li>
            </ul>
            </div>
            <p class="section-descri">
              Join us in our mission to provide exceptional legal services and
              make a positive impact in our community.
            </p>
          </div>
        </div>
      </div>
      <FrameComponent />
      <Section />
    </>
  );
};

export default Careers;
