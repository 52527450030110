import React, { useState } from 'react';
import axios from 'axios'; // Make sure axios is installed: npm install axios
import './FormComponent.css';
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { submitForm1 } from '../utils/api';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
const isValidPhoneNumber = (phoneNumber) => {
   
  const phoneRegex = /^\d{10}$/;
  
  return phoneRegex.test(phoneNumber);
};
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.phone || !formData.subject || !formData.message) {
      toast.error('All fields are required');
    //   setSuccess('');
      return;
    }
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error('Please enter a valid phone number');
      return;
    }
    try {
      await submitForm1(formData);
      toast.success('Form submitted successfully');
    
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    //   setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again later.');
    //   setSuccess('');
    }
  };

  return (
    <div className="privacy-policy-header">
      <form onSubmit={handleSubmit}>
        {/* {error && <div className="error-message">{error}</div>} */}
       
        <div className="list-of-links">
          <div className="info-items-container">
            <div className="divelementor-widget-container5">
              <img className="svg-icon1" alt="Name Icon" src="/svg-3.svg" />
            </div>
            <div className="input5">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="FULL NAME"
                style={{ textTransform: 'none' }}
              />
            </div>
          </div>
          <div className="heading5">
            <div className="divelementor-widget-container6">
              <img className="svg-icon2" alt="" src="/svg-4.svg" />
            </div>
            <div className="input5">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="EMAIL"
                style={{ textTransform: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="list-of-links1">
          <div className="divelementor-widget-container-parent">
            <div className="divelementor-widget-container7">
              <img className="svg-icon3" alt="" src="/svg-5.svg" />
            </div>
            <div className="input5">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="PHONE NUMBER"
                style={{ textTransform: 'none' }}
              />
            </div>
          </div>
          <div className="divelementor-widget-container-group">
            <div className="divelementor-widget-container8">
              <img className="svg-icon4" alt="" src="/svg-6.svg" />
            </div>
            <div className="input5">
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="SUBJECT"
                style={{ textTransform: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="heading6">
          <div className="form-section-section2">
            <div className="divelementor-widget-container9">
              <img className="svg-icon5" alt="" src="/svg-7.svg" />
            </div>
            <textarea
              className="full-name1"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="MESSAGE"
              rows="4"
              cols="50"
              style={{ marginLeft: '0px', color: 'black', outline: 'none' }}
            ></textarea>
          </div>
          {/* <div className="button8"> */}
            <button type="submit" className="button8 mt-2" style={{color:'white',fontWeight:'500'}}>Get In Touch<img className='submit-icon' src="/Symbol-16.svg" /> </button>

          {/* </div> */}
        </div>
        {/* {success && <div className="success-message">{success}</div>} */}
      </form>
      {/* <ToastContainer autoClose={3000} /> */}
    </div>
   
  );
};
export default FormComponent;

// export default FormComponent;
// import React, { useState } from 'react';
// import axios from 'axios'; // Make sure axios is installed: npm install axios
// import './FormComponent.css'
// const FormComponent = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     subject: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('/api/submit-form1', formData);
//       console.log(response.data);
//       // Reset form fields after successful submission
//       setFormData({
//         name: '',
//         email: '',
//         phone: '',
//         subject: '',
//         message: ''
//       });
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <div className="privacy-policy-header">
//       <form onSubmit={handleSubmit}>
//         <div className="list-of-links">
//           <div className="info-items-container">
//             <div className="divelementor-widget-container5">
//               <img className="svg-icon1" alt="" src="/svg-3.svg" />
//             </div>
//             <div className="input5">
//               <input
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 placeholder="FULL NAME"
//                 style={{ textTransform: 'none' }}
//               />
//             </div>
//           </div>
//           <div className="heading5">
//             <div className="divelementor-widget-container6">
//               <img className="svg-icon2" alt="" src="/svg-4.svg" />
//             </div>
//             <div className="input5">
//               <input
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 placeholder="EMAIL"
//                 style={{ textTransform: 'none' }}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="list-of-links1">
//           <div className="divelementor-widget-container-parent">
//             <div className="divelementor-widget-container7">
//               <img className="svg-icon3" alt="" src="/svg-5.svg" />
//             </div>
//             <div className="input5">
//               <input
//                 type="tel"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 placeholder="PHONE NUMBER"
//                 style={{ textTransform: 'none' }}
//               />
//             </div>
//           </div>
//           <div className="divelementor-widget-container-group">
//             <div className="divelementor-widget-container8">
//               <img className="svg-icon4" alt="" src="/svg-6.svg" />
//             </div>
//             <div className="input5">
//               <input
//                 type="text"
//                 name="subject"
//                 value={formData.subject}
//                 onChange={handleChange}
//                 placeholder="SUBJECT"
//                 style={{ textTransform: 'none' }}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="heading6">
//           <div className="form-section-section2">
//             <div className="divelementor-widget-container9">
//               <img className="svg-icon5" alt="" src="/svg-7.svg" />
//             </div>
//             <textarea
//               className="full-name1"
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//               placeholder="MESSAGE"
//               rows="4"
//               cols="50"
//               style={{ marginLeft: '0px', color: 'black', outline: 'none' }}
//             ></textarea>
//           </div>
//           {/* <div className="button8"> */}
//             <button type="submit" className="button8 mt-2" style={{color:'white'}}>Get Free Quote<img src="/Symbol-16.svg" /> </button>

//           {/* </div> */}
//         </div>
//       </form>
//     </div>
//   );
// };

// export default FormComponent;
