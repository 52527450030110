import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPublications } from "../utils/api";
const baseURL = process.env.REACT_APP_BASE_URL;

const PublicationsLeft = () => {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const publications = await fetchPublications();
        setPublications(publications);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {publications.map((item) => (
        <div
          key={item.id}
          className="symbol-facebook-item-link1"
          style={{
            border: "1px solid #eee",
            borderRadius: "2px",
            padding: "40px",
          }}
        >
          <div className="blog-card">
            <img
              className="article-link-blog-image3-7"
              loading="eager"
              alt=""
              src={`${baseURL}/${item.imgUrl}`}
            />
            <div className="symbol-twitter-item-link">
              <div className="article">
                <div className="list-item-attorneys">
                  <div className="list-item-practice-areas d-flex pt-2">
                    <div className="frame-careers p-1 pe-4">
                      <div className="link-privsa">
                        <img
                          className="pe-1"
                          src="/blogauthor.svg"
                          alt="Author"
                        />
                        {item.author}
                      </div>
                    </div>
                    <div className="link-privacy-policy p-1 pe-4">
                      <div className="september-15-20202">
                        <img
                          className="pe-1"
                          src="/calender.svg"
                          alt="Date"
                        />
                        {formatDate(item.date)}
                      </div>
                    </div>
                    <div className="frame-head-office p-1 pe-4">
                      <div className="link-litigation2">
                        <img
                          className="pe-1"
                          src="/category.svg"
                          alt="Category"
                        />
                        {item.category}
                      </div>
                    </div>
                  </div>
                  <h2
                    className="heading-2-container4 mt-4"
                    style={{ marginBottom: "20px" }}
                  >
                    {item.title}
                  </h2>
                </div>
                <div className="main-complementary">
                  <p className="event-description">{item.summary}</p>
                </div>
                <Link
                  className="read-btn"
                  style={{ textDecoration: 'none' }}
                  to={`/publications/${item.id}`}
                >
                  <button className="form-section-section-but" style={{ color: 'white' }}>Read More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PublicationsLeft;
