import Navbar from "../components/Navbar";
import ListContainer from "../components/ListContainer";
import FrameComponent2 from "../components/FrameComponent2";
import Section6 from "../components/Section6";
import Section5 from "../components/Section5";
import "./OurServices.css";
import FrameComponent from "../components/FrameComponent";
import Section from "../components/Section";
import SubscribeInputButton from "../components/SubscribeInputButton";

const OurServices = () => {
  return (
    <div className="our-services">
      <Navbar
        listItemLink="/list--item--link.svg"
        groupDivGap="1px"
        // centralSceneRight="18.15%"
        // centralSceneLeft="18.53%"
        sectionSectionPadding="0px var(--padding-12xs) 0px 0px"
        starPerformerPadding="var(--padding-3xs) var(--padding-10xl) var(--padding-3xs) var(--padding-11xl)"
        listItemLinkPadding="var(--padding-mini) var(--padding-lg) var(--padding-mini) var(--padding-10xl)"
        customerConnectColor="#fff"
        listItemColor="#fff"
        listItemColor1="#fff"
        ColorService={'#e86500'}
      />
      <ListContainer
        heading1AboutUS="Our Services"
        orderedListItemAboutUs="Our Services"
        propPadding="0px 0px var(--padding-81xl)"
        propPadding1="var(--padding-184xl) var(--padding-xl) var(--padding-185xl) var(--padding-2xl)"
        // propBackgroundImage="url('/section11@3x.png')"
        propPadding2="unset"
        propBackgroundImage="url('/services.jpg')"
      />
      <section className="frame-parent" >
        <div className="main-section-heading-3-p-parent">
          <div className="main-section1">Practice Areas</div>
          <h1 className="main-section2">We Offer A Client Focused Approach</h1>
        </div>
        <FrameComponent2 />
      </section>
      {/* <Section6
        itemLinkFacebook="/item--link--facebook.svg"
        itemLinkTwitter="/item--link--twitter-1.svg"
        itemLinkLinkedIn="/item--link--linkedin.svg"
        itemLinkInstagram="/item--link--instagram2.svg"
        propBackgroundImage="url('/section14@3x.png')"
      /> */}
        
      <FrameComponent />
      <Section />
    </div>
  );
};

export default OurServices;
