import Navbar from "../components/Navbar";
import ListContainer from "../components/ListContainer";
import InputField from "../components/InputField";
import Section6 from "../components/Section6";
import Section5 from "../components/Section5";
import "./AboutUs.css";
import FrameComponent from "../components/FrameComponent";
import SubscribeInputButton from "../components/SubscribeInputButton";
import Section from "../components/Section";
import FrameComponent3 from "../components/FrameComponent3";

const AboutUs = () => {
  return (
    <>
      <div className="about-us">
        <Navbar listItemLink="/list--item--link.svg" ColorAbout={"#e86500"} />
        <ListContainer
          heading1AboutUS="About US"
          orderedListItemAboutUs="About Us"
          propBackgroundImage="url('/egypt1.jpg')"
        />
        <section className="privacy-policy-link" style={{paddingBottom: '17px'}}>
          <div className="legal-info-link">
            <div className="main-section">We Safeguard the Rule of Law</div>
            <h1 className="main-section-container">
              <p className="at-privsa-lawyers">
                At Aljneibi Lawyers, We Believe that
              </p>
              <p className="no-one-should">No One Should Needlessly Suffer Injustice</p>
            </h1>
          </div>
          <div className="main-content-frame">
            <div className="header-wrapper">
              <div className="navigation-menu">
                <div className="logo-symbol">
                  <h3 className="privsa-law-offices" >
                    Al jneibi Law Offices are more than a law firm.
                  </h3>
                  <div>
                    <div className="for-most-of-container1">
                      <span>
                        <p className="add-margin margin-top" >
                          At ALJNEIBI & PARTNERS - Advocates and Legal Consultants,
                          we understand the complexity of legal matters in
                          today's rapidly evolving business landscape. Located
                          in the heart of Dubai, UAE, our firm is committed to
                          providing comprehensive legal solutions tailored to
                          meet the diverse needs of our clients.
                        </p>
                        <p className="add-margin">
                          While it’s true that our attorneys have
                          decades of combined legal experience, and that over
                          our 30+ year history as a Firm we have won thousands
                          of cases for our clients. For most of our clients, we
                          meet them when the worst thing that ever could happen
                          to them has happened. We know that they want more than
                          an attorney; they want someone who will listen
                          attentively, treat them with respect and give them a legal solution.
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* <div className="for-most-of-container">
                    <span>
                      <p className="for-most-of">
                        For most of our clients, we meet them when the worst thing
                        that ever could happen to them has happened. We know that they want
                        more than an attorney; they want someone who will listen
                        attentively, treat them with respect.
                      </p>
                    </span>
                  </div> */}
                </div>
                <div className="logo-container">
                  <div className="main-nav mt-5">
                    <div className="hamburger-icon">
                      <div className="search-bar">
                        <div className="talk-to-an">Talk to an Expert</div>
                        <div className="main-content">
                          {/* <h3 className="symbol"></h3> */}

                          <div className="banner">
                            <span className="">
                              <img className="pe-3" src="/Symbol4.svg" />
                            </span>
                            +971543000775
                          </div>
                        </div>
                        <div className="talk-to-an">Email Us</div>
                        <div className="main-content">
                          <div className="banner">
                            <span className="">
                              <img className="pe-3" src="/Symbol05.svg" />
                              info@ajplawyers.ae
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="divelementor-widget-wrap-icon"
                      loading="eager"
                      alt=""
                      src="/divelementorwidgetwrap.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="services">
              <div className="service-card">
                <div className="divelementor-widget-wrap">
                  <img
                    className="feature-icon-3png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-3png1@2x.png"
                  />
                  <div className="features">
                    <b className="heading-3">409</b>
                    <div className="qualified-lawyers">Qualified Lawyers</div>
                  </div>
                </div>
                <div className="divelementor-widget-wrap1">
                  <img
                    className="feature-icon-4png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-4png1@2x.png"
                  />
                  <div className="heading-3-275-parent">
                    <b className="heading-31">275</b>
                    <div className="ranked-in-super">Ranked in Super Law</div>
                  </div>
                </div>
              </div>
              <div className="form-input">
                <div className="divelementor-widget-wrap2">
                  <img
                    className="feature-icon-2png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-2png1@2x.png"
                  />
                  <div className="links-container">
                    <b className="heading-32">26</b>
                    <div className="cases-fully-dismiss">Cases Fully Dismiss</div>
                  </div>
                </div>
                <div className="sub-nav-links">
                  <div className="search-box">
                    <img
                      className="feature-icon-1png"
                      loading="eager"
                      alt=""
                      src="/feature-icon-1png@2x.png"
                    />
                    <div className="navigation-links">
                      <b className="heading-33">409</b>
                      <div className="qualified-lawyers1">Qualified Lawyers</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <section className="privacy-policy-link"  style={{paddingBottom: '17px'}}>
          <div className="legal-info-link"  data-aos='fade-up'>
            <div className="main-section">Discover Our Vision</div>
            <h1 className="main-section-container">
              <p className="at-privsa-lawyers">
                Our Vision
              </p>
              {/* <p className="no-one-should">No One Should Needlessly</p> */}
            </h1>
          </div>
          <div className="main-content-frame" data-aos='fade-up'>
            <div className="header-wrapper">
              <div className="navigation-menu" style={{display:'flex',alignItems:'center'}}>
                <div className="logo-symbol">
                  {/* <h3 className="privsa-law-offices">
                    Al jneibi Law Offices is more than a law firm.
                  </h3> */}
                  <div>
                    <div className="for-most-of-container2" style={{marginTop:'-20px'}}>
                      <span>
                        <p className="vision-add-margin">
                          Trust, honesty, reliability and integrity coupled with outstanding performance.
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* <div className="for-most-of-container">
                    <span>
                      <p className="for-most-of">
                        For most of our clients, we meet them when the worst thing
                        that ever could happen to them has happened. We know that they want
                        more than an attorney; they want someone who will listen
                        attentively, treat them with respect.
                      </p>
                    </span>
                  </div> */}
                </div>
                
              </div>
            </div>
            {/* <div className="services">
              <div className="service-card">
                <div className="divelementor-widget-wrap">
                  <img
                    className="feature-icon-3png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-3png1@2x.png"
                  />
                  <div className="features">
                    <b className="heading-3">409</b>
                    <div className="qualified-lawyers">Qualified Lawyers</div>
                  </div>
                </div>
                <div className="divelementor-widget-wrap1">
                  <img
                    className="feature-icon-4png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-4png1@2x.png"
                  />
                  <div className="heading-3-275-parent">
                    <b className="heading-31">275</b>
                    <div className="ranked-in-super">Ranked in Super Law</div>
                  </div>
                </div>
              </div>
              <div className="form-input">
                <div className="divelementor-widget-wrap2">
                  <img
                    className="feature-icon-2png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-2png1@2x.png"
                  />
                  <div className="links-container">
                    <b className="heading-32">26</b>
                    <div className="cases-fully-dismiss">Cases Fully Dismiss</div>
                  </div>
                </div>
                <div className="sub-nav-links">
                  <div className="search-box">
                    <img
                      className="feature-icon-1png"
                      loading="eager"
                      alt=""
                      src="/feature-icon-1png@2x.png"
                    />
                    <div className="navigation-links">
                      <b className="heading-33">409</b>
                      <div className="qualified-lawyers1">Qualified Lawyers</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <section className="privacy-policy-link" data-aos='fade-up'>
          <div className="legal-info-link">
            <div className="main-section">Explore Our Mission</div>
            <h1 className="main-section-container">
              <p className="at-privsa-lawyers">
                Our Mission
              </p>
              {/* <p className="no-one-should">No One Should Needlessly</p> */}
            </h1>
          </div>
          <div className="main-content-frame">
            <div className="header-wrapper">
              <div className="navigation-menu">
                <div className="logo-symbol">
                  {/* <h3 className="privsa-law-offices">
                    Al jneibi Law Offices is more than a law firm.
                  </h3> */}
                  <div>
                    <div className="mission-content">
                      <span>
                        <p className="mission-add-margin">
                          To enhance the advocacy profession within a framework of high principles, professional ethics through raising the awareness of the community about the significance of those ethics in a bid to safeguard their rights gruaranteed by the law. We do this through extending unique services and legal job based on the best criteria in a timely manner taking into our account the interests of our customers while adhering to professional ethics and confidentiality of the service.
                        </p>
                        <p className="mission-add-margin">
                          To provide quality legal services through the employment of distinct individual skills and efficiency of professional work team capable to extend legal services to clients before all relevant legal or semi-legal parties and departments, administrative and government bodies to the benefit of clients before all relevant legal or semi-legal parties and departments, administrative and government bodies to the benefit of customers in implementation od advocacy approved norms and practices.
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* <div className="for-most-of-container">
                    <span>
                      <p className="for-most-of">
                        For most of our clients, we meet them when the worst thing
                        that ever could happen to them has happened. We know that they want
                        more than an attorney; they want someone who will listen
                        attentively, treat them with respect.
                      </p>
                    </span>
                  </div> */}
                </div>
                
              </div>
            </div>
            {/* <div className="services">
              <div className="service-card">
                <div className="divelementor-widget-wrap">
                  <img
                    className="feature-icon-3png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-3png1@2x.png"
                  />
                  <div className="features">
                    <b className="heading-3">409</b>
                    <div className="qualified-lawyers">Qualified Lawyers</div>
                  </div>
                </div>
                <div className="divelementor-widget-wrap1">
                  <img
                    className="feature-icon-4png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-4png1@2x.png"
                  />
                  <div className="heading-3-275-parent">
                    <b className="heading-31">275</b>
                    <div className="ranked-in-super">Ranked in Super Law</div>
                  </div>
                </div>
              </div>
              <div className="form-input">
                <div className="divelementor-widget-wrap2">
                  <img
                    className="feature-icon-2png"
                    loading="eager"
                    alt=""
                    src="/feature-icon-2png1@2x.png"
                  />
                  <div className="links-container">
                    <b className="heading-32">26</b>
                    <div className="cases-fully-dismiss">Cases Fully Dismiss</div>
                  </div>
                </div>
                <div className="sub-nav-links">
                  <div className="search-box">
                    <img
                      className="feature-icon-1png"
                      loading="eager"
                      alt=""
                      src="/feature-icon-1png@2x.png"
                    />
                    <div className="navigation-links">
                      <b className="heading-33">409</b>
                      <div className="qualified-lawyers1">Qualified Lawyers</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <InputField />
        {/* <section className="image-with-text">
          <div className="services-section">
            <div className="heading-34">Our Achievements</div>
            <h1 className="heading-2">Awards and Recognition</h1>
          </div>
          <div className="header-text">
            <div className="heading">
              <img
                className="award-logo1png-icon"
                loading="eager"
                alt=""
                src="/award-logo1png1@2x.png"
              />
            </div>
            <img
              className="award-logo2png-icon"
              loading="eager"
              alt=""
              src="/award-logo2png1@2x.png"
            />
            <div className="award-logo3png-wrapper">
              <img
                className="award-logo3png-icon"
                loading="eager"
                alt=""
                src="/award-logo3png1@2x.png"
              />
            </div>
            <img
              className="award-logo4png-icon"
              loading="eager"
              alt=""
              src="/award-logo4png1@2x.png"
            />
          </div>
        </section> */}
      </div>
      <FrameComponent3 />
      <FrameComponent />
      <Section />
    </>
  );
};

export default AboutUs;
