
import "./Section3.css";

const Section3 = () => {

  return (
    <>
    <div className="section17" >
      <div className="divservice-content">
        <div className="before1" />
        <div className="practice-areas-list1">
          <div className="attorneys-list2">
            <b className="heading-38">Intellectual Property</b>
            <div className="premier-trial-lawyers-container">
              <span>
                <p className="premier-trial-lawyers">
                We offer trademark registration, copyright protection, and IP litigation.
                </p>
                {/* <p className="in-the-nation">in the nation who</p> */}
              </span>
            </div>
          </div>
          <a href="/our-services"><img className="link-icon2" alt="" src="/link.svg" /></a>
        </div>
      </div>
    </div>
    <div className="section17" >
      <div className="divservice-content">
        <div className="before1" />
        <div className="practice-areas-list1">
          <div className="attorneys-list2">
            <b className="heading-38">Employment Law</b>
            <div className="premier-trial-lawyers-container">
              <span>
                <p className="premier-trial-lawyers">
                
We offer employment contract advice, dispute resolution, and labor law compliance.
                </p>
                {/* <p className="in-the-nation">in the nation who</p> */}
              </span>
            </div>
          </div>
          <a href="/our-services"> <img className="link-icon2" alt="" src="/link.svg" /></a>   
        </div>
      </div>
    </div>
    <div className="section17" >
      <div className="divservice-content">
        <div className="before1" />
        <div className="practice-areas-list1">
          <div className="attorneys-list2">
            <b className="heading-38">Family Law</b>
            <div className="premier-trial-lawyers-container">
              <span>
                <p className="premier-trial-lawyers">
                
Dedicated family lawyers offer meticulous guidance in sensitive family matters.
                </p>
                {/* <p className="in-the-nation">in the nation who</p> */}
              </span>
            </div>
          </div>
          <a href="/our-services"> <img className="link-icon2" alt="" src="/link.svg" /></a> 
        </div>
      </div>
    </div>
    </>
  );
};

export default Section3;
